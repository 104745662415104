import { COLUMN_WIDTH } from "../../../../constants/ColumnWidth";
import { useGenGridCol } from "../../../../utils/ComponentHelper";
import { TableColumnKey } from '../types/TableColumnKeys';

const useTableColumns = () => {
    const columns = [
        useGenGridCol(TableColumnKey.OrderId, {
            minWidth: COLUMN_WIDTH.WalletOrderId.MinWidth,
        }),
        useGenGridCol(TableColumnKey.OrderType, {
            minWidth: COLUMN_WIDTH.WalletOrderType.MinWidth,
        }),
        useGenGridCol(TableColumnKey.ProgramName, {
            minWidth: COLUMN_WIDTH.ProgramName.MinWidth,
        }),
        useGenGridCol(TableColumnKey.CustomerNumber, {
            minWidth: COLUMN_WIDTH.CustomerNumber.MinWidth,
        }),
        useGenGridCol(TableColumnKey.ClientId),
        useGenGridCol(TableColumnKey.WalletType, {
            minWidth: COLUMN_WIDTH.WalletOrderType.MinWidth,
        }),
        useGenGridCol(TableColumnKey.TransactionId, {
            minWidth: COLUMN_WIDTH.WalletTransactionId.MinWidth,
        }),
        useGenGridCol(TableColumnKey.TransactionType, {
            minWidth: COLUMN_WIDTH.WalletOrderType.MinWidth,
        }),
        useGenGridCol(TableColumnKey.Direction),
        useGenGridCol(TableColumnKey.Currency),
        useGenGridCol(TableColumnKey.Amount, {
            minWidth: COLUMN_WIDTH.WalletAmount.MinWidth,
        }),
        useGenGridCol(TableColumnKey.PostBalance, {
            minWidth: COLUMN_WIDTH.WalletAmount.MinWidth,
        }),
        useGenGridCol(TableColumnKey.PostFrozenAmount, {
            minWidth: COLUMN_WIDTH.WalletAmount.MinWidth,
        }),
        useGenGridCol(TableColumnKey.FromClientId, {
            minWidth: COLUMN_WIDTH.WalletClientId.MinWidth,
        }),
        useGenGridCol(TableColumnKey.ToClientId, {
            minWidth: COLUMN_WIDTH.WalletClientId.MinWidth,
        }),
        useGenGridCol(TableColumnKey.SendingAddress, {
            minWidth: COLUMN_WIDTH.WalletOrderId.MinWidth,
        }),
        useGenGridCol(TableColumnKey.ReceivingAddress, {
            minWidth: COLUMN_WIDTH.WalletOrderId.MinWidth,
        }),
        useGenGridCol(TableColumnKey.CreationTime, {
            minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
        }),
        useGenGridCol(TableColumnKey.LastModifiedTime, {
            minWidth: COLUMN_WIDTH.LastModifiedDate.MinWidth,
        }),
    ];

    return columns;
};

export default useTableColumns;