import { useQuery } from "react-query";
import APIs from "../api";
import { useDispatch } from "react-redux";

import { ICustomerLabel } from "../api/types";
import { selectCustomerLabelList, setCustomerLabelList } from "../reducer/customerLabelSlice";
import { useAppSelector } from "../reducer/hooks";

export async function useFetchCustomerLabel() {
  const dispatch = useDispatch();

  useQuery('customerLabel', APIs.MerchantPortal.customerLabel.getCustomerLabel, {
    onSuccess: (dbRes) => {
      dispatch(
        setCustomerLabelList(dbRes as ICustomerLabel[])
      )
    }
  })
}

export function useCustomerLabel() {
  const {
    customerLabelList
  } = useAppSelector(selectCustomerLabelList)


  return {
    EnumCustomerLabelList: customerLabelList
  }
}