import { EnumPurchaseOrderStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Eshop';
import { SingleSelection } from '../../../../components';
import { useTranslation } from '../../../../hooks';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
  onClear: () => void;
}

const PurchaseStatusSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { t, tc } = useTranslation('purchaseAssetOrder');

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: t('purchaseStatus') })}
      value={value || ''}
      onChange={onChange}
      clearSelect={onClear}
      enumData={EnumPurchaseOrderStatus}
    />
  );
};

export default PurchaseStatusSingleSelection;
