import { CustomerManagement } from "@wallet-manager/pfh-pmp-node-def-types/dist/src/FeatureCode/MerchantPortal";
import { Iprefix } from "../../../hooks/useTranslation";
import { FilterFields } from "./types/FilterFields";

const translatePrefix: Iprefix = 'customerLabel';

const PermissionKey = CustomerManagement.CustomerLabel;
const CreateLabelPermissionKey = CustomerManagement.CustomerLabel.Create;
const DeleteLabelPermissionKey = CustomerManagement.CustomerLabel.Delete;
const ExportPermissionKey = CustomerManagement.CustomerAccount.Export


const translateKeyObj = {
  operation: 'operation',
  delete: 'delete',
  customerLabel: 'customerLabel',
  createdBy: 'createdBy',
  creationTime: 'creationTime',
  lastModifiedTime: 'lastModifiedTime',
} as const

const TableColumnKey = {
  Operation: translateKeyObj.operation,
  CustomerLabel: translateKeyObj.customerLabel,
  CreatedBy: translateKeyObj.createdBy,
  CreationTime: translateKeyObj.creationTime,
  LastModifiedTime: translateKeyObj.lastModifiedTime,
} as const

const FilterFieldsKeys = {
  CreatedDateFrom: 'createdDateFrom',
  CreatedDateTo: 'createdDateTo',
  LastModifiedTimeFrom: 'lastModifiedTimeFrom',
  LastModifiedTimeTo: 'lastModifiedTimeTo',
  CustomerLabel: translateKeyObj.customerLabel
} as const

const initFields: FilterFields = {
  [FilterFieldsKeys.CreatedDateFrom]: '',
  [FilterFieldsKeys.CreatedDateTo]: '',
  [FilterFieldsKeys.LastModifiedTimeFrom]: '',
  [FilterFieldsKeys.LastModifiedTimeTo]: '',
  [FilterFieldsKeys.CustomerLabel]: '',
};

const acceptedCharacters = ['.', ',', ';', ':', '?', '!', '-', '_', '(', ')', '[', ']', '{', '}', '<', '>', '\\', '|', '#', '@', '$', '^', '&', '*', '=', '+', '~', ' ']

export {
  initFields,
  translatePrefix,
  PermissionKey,
  CreateLabelPermissionKey,
  ExportPermissionKey,
  translateKeyObj,
  TableColumnKey,
  FilterFieldsKeys,
  DeleteLabelPermissionKey,
  acceptedCharacters
}