import { defaultListParams } from "../../../common/filterTable/constants/defaultListParams";
import { cleanParams } from "../../../common/filterTable/helpers/cleanParams";
import { FilterFields } from "../types/FilterFields";
import { GetListParams } from "../types/GetListParams";

const useGetListParams = () => {
    const getListParams = (fields: FilterFields) => {
        const {
            createdDateFrom,
            createdDateTo,
            lastModifiedTimeFrom,
            lastModifiedTimeTo,
            programNames,
            walletType,
            customerNumber,
            clientId,
            orderId,
            orderType,
            transactionId,
            transactionType,
            direction,
            currency,
            sendingAddress,
            receivingAddress,
        } = fields;

        const params: GetListParams = {
            ...defaultListParams,
            createdDateFrom,
            createdDateTo,
            lastModifiedDateFrom: lastModifiedTimeFrom,
            lastModifiedDateTo: lastModifiedTimeTo,
            programNames: programNames,
            walletType,
            customerNumber,
            clientId,
            merchantOrderId: orderId,
            orderType,
            ledgerTransactionId: transactionId,
            transType: transactionType,
            direction,
            currency,
            fromAddress: sendingAddress,
            toAddress: receivingAddress,
        };
        return cleanParams(params)
    };
    
    return {
        getListParams,
    };
};

export default useGetListParams;