import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { ICustomerLabel } from "../api/types";

const initialState: {
  customerLabelList: Record<string, string>;
} = {
  customerLabelList: {}
}

export const customerLabelSlice = createSlice({
  name: 'customerLabel',
  initialState,
  reducers: {
    setCustomerLabelList: (
      state,
      action: PayloadAction<ICustomerLabel[]>
    ) => {
      if (!action.payload) { return; }
      state.customerLabelList = action.payload.reduce(
        (acc: Record<string, string>, { customerLabel }) => {
          if (customerLabel === '') { return acc; }

          return {
            ...acc,
            [customerLabel]: customerLabel,
          };
        },
        {}
      )
    }
  }
})


export const { setCustomerLabelList } = customerLabelSlice.actions;
export const selectCustomerLabelList = (state: RootState) => state.customerLabel;
export default customerLabelSlice.reducer;