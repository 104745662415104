import { EnumLedgerDirection } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

export const getWalletDirectionDisplayValue = (
  type: number,
  translateFunc: (val: any) => string
) => {
  const value = EnumLedgerDirection[type];

  if (!value) return '';

  return translateFunc(value);
};
