import { Dispatch, SetStateAction, useState } from "react"
import { DialogInOne } from "../../../components"
import { IdialogInOneProps } from "../../../components/DialogInOne"
import { useAlerting, useTranslation } from "../../../hooks"
import { translatePrefix, TableColumnKey, acceptedCharacters } from "./config"
import { GridBox } from "../../../components/Layout"
import CustomerLabelTextField from "../../../features/common/filterTable/components/Filters/CustomerLabelTextField"
import { checkIsChineseCharacter, checkIsWhiteListedSpecialCharacter } from "../../../helper"

interface Iprops {
  isOpen: boolean
  closeDialog: () => void
  customerLabel: string
  setCustomerLabel: Dispatch<SetStateAction<string>>
  handleCreateLabelSubmit: () => Promise<void>
}

const DialogCreateLabel = (props: Iprops) => {
  const { isOpen, closeDialog, customerLabel, setCustomerLabel, handleCreateLabelSubmit } = props
  const { tc } = useTranslation(translatePrefix)
  const { alerting } = useAlerting();

  const handleCloseDialog = () => {
    setCustomerLabel('')
    closeDialog()
  }

  const labelElePairArr: Array<[string, JSX.Element]> = [
    [
      TableColumnKey.CustomerLabel,
      <CustomerLabelTextField
        value={customerLabel}
        onChange={(e) => {
          setCustomerLabel(e.target.value.toUpperCase())
        }}
      />
    ]
  ]

  const dialogContent = <GridBox labelElePairArr={labelElePairArr} columnCount={1} />

  const handleSubmit = async () => {
    const trimmedLabel = customerLabel.trim()

    if (!checkIsWhiteListedSpecialCharacter(trimmedLabel, acceptedCharacters) || checkIsChineseCharacter(trimmedLabel)) {
      alerting('error', tc('phInputFieldCheck', { fieldName: acceptedCharacters.join(' ') }))
      return
    }
    handleCreateLabelSubmit()
  }
  const dialogConfig: IdialogInOneProps = {
    title: tc('add_label'),
    self: {
      open: isOpen,
      onClose: handleCloseDialog
    },
    content: dialogContent,
    onConfirm: handleSubmit,
    onCancel: handleCloseDialog,
    size: 'sm',
    dialogActionStyling: { padding: '16px' },
  }
  return <DialogInOne {...dialogConfig} />
}

export default DialogCreateLabel