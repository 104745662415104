import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { Transactions } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/FeatureCode/MerchantPortal';

import api from '../../../api';
import { ApiResult } from '../../../api/types';
import { ExportBtn } from '../../../components/Button';
import { useDatePicker } from '../../../components/DatePicker';
import { DivideLine } from '../../../components/Layout';
import Table from '../../../features/common/filterTable/components/Table';
import { convertToExportRows } from '../../../features/common/filterTable/helpers/convertToExportRows';
import FilterContainer from '../../../features/common/filterTable/layout/FilterContainer';
import FilterTableLayoutContainer from '../../../features/common/filterTable/layout/FilterTableLayoutContainer';
import TableContainer from '../../../features/common/filterTable/layout/TableContainer';
import FilterSection from '../../../features/transaction/walletLedgerTransactions/components/FilterSection';
import useGetListParams from '../../../features/transaction/walletLedgerTransactions/helpers/useGetListParams';
import useTableColumns from '../../../features/transaction/walletLedgerTransactions/helpers/useTableColumns';
import useTableRows from '../../../features/transaction/walletLedgerTransactions/helpers/useTableRows';
import {
    FilterFields,
    FilterFieldsKeys,
} from '../../../features/transaction/walletLedgerTransactions/types/FilterFields';
import { GetListRes } from '../../../features/transaction/walletLedgerTransactions/types/GetListRes';
import { useAlerting, usePermission, useTranslation } from '../../../hooks';
import useTabs from '../../../hooks/useTabs';
import { downloadFiles, getFullApiResponse } from '../../../utils';
import { useZusDialogStore, useZusTranslatePrefixStore } from '../../../zustand/store';
import useCollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import FilterSectionActionRow from '../../../features/common/filterTable/layout/FilterSectionActionRow';
import ToggleFilterButton from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';


const initFields = {
    [FilterFieldsKeys.createdDateFrom]: '',
    [FilterFieldsKeys.createdDateTo]: '',
    [FilterFieldsKeys.lastModifiedTimeFrom]: '',
    [FilterFieldsKeys.lastModifiedTimeTo]: '',
    [FilterFieldsKeys.programNames]: [],
    [FilterFieldsKeys.walletType]: '',
    [FilterFieldsKeys.customerNumber]: '',
    [FilterFieldsKeys.clientId]: '',
    [FilterFieldsKeys.orderId]: '',
    [FilterFieldsKeys.orderType]: [],
    [FilterFieldsKeys.transactionId]: '',
    [FilterFieldsKeys.transactionType]: [],
    [FilterFieldsKeys.direction]: '',
    [FilterFieldsKeys.currency]: '',
    [FilterFieldsKeys.sendingAddress]: '',
    [FilterFieldsKeys.receivingAddress]: '',
}

const translationPrefix = 'walletLedgerTransactions';

const WalletLedgerTransactions = () => {
    const { getListParams } = useGetListParams();

    const [page, setPage] = useState(0);
    const [fields, setFields] = useState<FilterFields>(initFields);
    const [listParams, setListParams] = useState(getListParams(fields));
    const [list, setList] = useState<ApiResult<GetListRes>>({ rows: [], count: 0 });

    const zusDialog = useZusDialogStore();
    const { hasPermission } = usePermission();
    const { alerting } = useAlerting();
    const { setTranslatePrefix } = useZusTranslatePrefixStore();
    const { t, tc } = useTranslation(translationPrefix);
    const { Tabs } = useTabs([{ name: tc('table'), value: '' }]);
    const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

    const columns = useTableColumns();
    const { rows, totalCounts, convertToTableRows } = useTableRows({ apiList: list });

    const isShowExportBtn = hasPermission(Transactions.TcspTransactions.Export);

    const DateObj = {
        CreationTime: useDatePicker(),
        LastModifiedTime: useDatePicker(),
    };

    useEffect(() => setTranslatePrefix(translationPrefix), []);

    const handleSettled = () => zusDialog.closeExtra();

    const handleSuccess = (data: ApiResult<GetListRes>) => {
        if (!data) return;
        setList(data);
    };

    const handleQuery = () => {
        zusDialog.openExtra('loadingDialog');
        const { currentTime, ...cleanParams } = listParams;
        return api.Transaction.walletLedgerTransactions.getWalletLedgerTransactions(cleanParams);
    };

    useQuery(['getWalletLedgerTransactions', listParams], handleQuery, {
        onSettled: handleSettled,
        onSuccess: handleSuccess,
    });

    const onExport = async () => {
        const params = getListParams(fields);
        const { currentTime, ...cleanParams } = params;

        if (totalCounts === 0) {
            return alerting('error', tc('no_data_export'));
        }

        const apiFn = (page: number, pageSize: number, signal: any) =>
            api.Transaction.walletLedgerTransactions.getExport({ ...cleanParams, page, pageSize }, { signal });

        const rawRes = await getFullApiResponse(apiFn, totalCounts, true);

        if (rawRes.length === 0) return;

        const omitKeys = ['id', 'rawData'];
        const tableRows = convertToTableRows({ rows: rawRes } as ApiResult<GetListRes>);
        const exportRows = convertToExportRows({ data: tableRows, omitKeys, translateFunc: t });

        downloadFiles(`Wallet Ledger Transaction`, exportRows, {});
    };

    useEffect(() => {
        // sync fields state with date picker
        setFields((fields) => {
            return {
                ...fields,
                [FilterFieldsKeys.createdDateFrom]: DateObj.CreationTime.start,
                [FilterFieldsKeys.createdDateTo]: DateObj.CreationTime.end,
                [FilterFieldsKeys.lastModifiedTimeFrom]: DateObj.LastModifiedTime.start,
                [FilterFieldsKeys.lastModifiedTimeTo]: DateObj.LastModifiedTime.end,
            };
        });
    }, [
        DateObj.CreationTime.start,
        DateObj.CreationTime.end,
        DateObj.LastModifiedTime.start,
        DateObj.LastModifiedTime.end,
    ]);


    const onPageChange = (page: number) => {
        setPage(page);
        setListParams({ ...listParams, page });
    };

    const onFilterSearch = () => {
        setPage(0);
        setListParams({
            ...getListParams(fields),
            page: 0,
            currentTime: Date.now(),
        });
    };

    const onFilterReset = () => {
        setFields(initFields);
        DateObj.CreationTime.clearDate();
        DateObj.LastModifiedTime.clearDate();
    };


    return (
        <FilterTableLayoutContainer>
            <FilterContainer>
                <FilterSectionActionRow>
                    <ToggleFilterButton isCollapse={isCollapse} onClick={toggleFilterCollapse} />
                </FilterSectionActionRow>
                <FilterSection
                    fields={fields}
                    setFields={setFields}
                    onSearch={onFilterSearch}
                    onReset={onFilterReset}
                    DateObj={DateObj}
                    isCollapse={isCollapse}
                />
            </FilterContainer>
            <DivideLine />
            <TableContainer>
                <ExportBtn onExport={onExport} isShow={isShowExportBtn} />
                <Tabs>
                    <Table
                        columns={columns}
                        rows={rows}
                        rowCount={totalCounts}
                        page={page}
                        onPageChange={onPageChange}
                    />
                </Tabs>
            </TableContainer>
        </FilterTableLayoutContainer>
    );
};

export default WalletLedgerTransactions;