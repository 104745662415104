import React, { ReactNode, SetStateAction } from 'react';

import { useGenFilterField } from '../../../../utils/ComponentHelper';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';
import { Filter } from '../../../../features/common/filterTable/types';
import CollapsibleFilters from '../../../../features/common/filterTable/components/Filters/CollapsibleFilters';
import CustomerIdTextField from '../../../../features/common/filterTable/components/Filters/CustomerIdTextField';
import CustomerNumberTextField from './CustomerNumberTextField';
import PurchaseOrderIdTextField from './PurchaseOrderIdTextField';
import EShopReferenceIdTextField from '../../../../features/common/filterTable/components/Filters/EShopReferenceIdTextField';
import PurchaseStatusSingleSelection from './PurchaseStatusSingleSelection';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  isCollapse?: boolean;
  DateObj: any;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, DateObj, isCollapse } = props;

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const filters: Filter[] = [
    { labelKey: 'creationTime', filter: <DateObj.CreationTime.Picker type="dateTime" /> },
    { labelKey: 'lastModifiedTime', filter: <DateObj.LastModifiedTime.Picker type="dateTime" /> },
    {
      labelKey: FilterFieldsKeys.customerId,
      filter: (
        <CustomerIdTextField
          value={fields[FilterFieldsKeys.customerId]}
          onChange={(e) => updateField(FilterFieldsKeys.customerId, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.customerNumber,
      filter: (
        <CustomerNumberTextField
          value={fields[FilterFieldsKeys.customerNumber]}
          onChange={(e) => updateField(FilterFieldsKeys.customerNumber, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.purchaseOrderId,
      filter: (
        <PurchaseOrderIdTextField
          value={fields[FilterFieldsKeys.purchaseOrderId]}
          onChange={(e) => updateField(FilterFieldsKeys.purchaseOrderId, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.eShopReferenceId,
      filter: (
        <EShopReferenceIdTextField
          value={fields[FilterFieldsKeys.eShopReferenceId]}
          onChange={(e) => updateField(FilterFieldsKeys.eShopReferenceId, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.purchaseStatus,
      filter: (
        <PurchaseStatusSingleSelection
          value={fields[FilterFieldsKeys.purchaseStatus]}
          onChange={(e) => updateField(FilterFieldsKeys.purchaseStatus, e.target.value)}
          onClear={() => updateField(FilterFieldsKeys.purchaseStatus, '')}
        />
      ),
    },
  ];

  return (
    <>
      <CollapsibleFilters
        isCollapse={isCollapse}
        filters={filters}
        onSearch={onSearch}
        onReset={onReset}
      />
    </>
  );
};

export default FilterSection;
