export enum TableColumnKey {
  PurchaseOrderId = 'purchaseOrderId',
  CustomerId = 'customerId',
  CustomerNumber = 'customerNumber',
  AssetCurrency = 'assetCurrency',
  AssetUnitPrice = 'assetUnitPrice',
  AssetQuantity = 'assetQuantity',
  AssetTotalAmount = 'assetTotalAmount',
  PurchaseStatus = 'purchaseStatus',
  PaymentUnitPrice = 'paymentUnitPrice',
  PaymentAmount = 'paymentAmount',
  ActualPaymentAmount = 'actualPaymentAmount',
  EShopReferenceId = 'eShopReferenceId',
  ErrorReason = 'errorReason',
  CreationTime = 'creationTime',
  LastModifiedTime = 'lastModifiedTime',
}
