import { addHours } from 'date-fns';

import { store } from '../reducer/store';
import { toDBTime } from '../utils';

export const displayAmountWithCurrency = (
  value: string | number | undefined,
  currency: string | undefined
) => {
  const displayCurrency = currency ? currency : '';
  return value === 0 || value ? value + ` ${displayCurrency}` : '';
};

export const convertDateOrDatetimeToDbFormat = (
  date: Date | null,
  type: 'date' | 'datetime',
  config?: { isTimezoneConvert: boolean }
) => {
  if (!date) {
    return '';
  }
  const localUTCdiff = Number(store.getState().profile.timezone) * -1 || 0;
  const usedUTCDiff = config?.isTimezoneConvert ? localUTCdiff : 0;
  const convertedDateTimeStr = toDBTime(addHours(date, usedUTCDiff));
  if (type === 'date') {
    return convertedDateTimeStr.split('T')[0];
  }
  return convertedDateTimeStr;
};

export const checkIsChineseCharacter = (value: string) => {
  const regex = /[\u4e00-\u9fa5]/;

  return regex.test(value);
};

export const checkIsWhiteListedSpecialCharacter = (
  value: string,
  whiteList: string[] = [" ", "-", "_"]
) => {
  const whiteListValues = whiteList.map((item) => `\\${item}`).join("");

  const pattern = `^(?! )[a-zA-Z0-9${whiteListValues}]*$`;
  const regex = new RegExp(pattern);

  return regex.test(value);
};