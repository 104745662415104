import { MpTextField } from '../../../../../components';
import { useTranslation } from '../../../../../hooks';

interface IProps {
    label?: string;
    value: any;
    onChange: (e: any) => void;
}

const EShopReferenceIdTextField = (props: IProps) => {
    const { label, value, onChange } = props;
    
    const { t, tc } = useTranslation('eshopOrder');

    return (
        <MpTextField
            label={label || tc('phInputField', { fieldName: t('eShopReferenceId') })}
            value={value}
            onChange={onChange}
        />
    );
};

export default EShopReferenceIdTextField;