import auth from './auth';
import customer from './customer';
import profile from './profile';
import query from './query';
import customerLabel from './customerLabel';

export default {
  auth,
  profile,
  query,
  customer,
  customerLabel
};
