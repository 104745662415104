import { subMinutes, subYears } from 'date-fns';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { SxProps } from '@mui/system';
import {
  EnumBusinessIndustry,
  EnumDocumentType,
  EnumEducationLevel,
  EnumEmploymentStatus,
  EnumGender,
  EnumJobTitle,
  EnumMaritalStatus,
  EnumPurposeApplying,
  EnumResidentialStatus,
  EnumTitle,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/ApiEnum';
import {
  EnumCustomerApplicationType,
  EnumIdvMethod,
  EnumIdvStatus,
  EnumQuestionType,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { OpRedirect } from '../../../../assets/icons';
import { MpTextField, MpTextFieldNumberOnly, SingleSelection } from '../../../../components';
import { OpIconButton } from '../../../../components/Button';
import TimePicker from '../../../../components/DatePicker';
import COUNTRY_CODE_LIST from '../../../../components/i18nProvider/countryCodeList.json';
import { Box, SelectChangeEvent } from '../../../../components/MuiGenerals';
import MpTextFieldEnglishOnly from '../../../../components/TextField/MpTextFieldEnglishOnly';
import TogglableIdNumber from '../../../../components/TogglableIdNumber';
import { displayAmountWithCurrency } from '../../../../helper';
import { getSourceDisplayValue } from '../../../../helper/getSourceDisplayValue';
import { useAlerting, useTranslation } from '../../../../hooks';
import useGridBoxInputValidation, { Iconfig } from '../../../../hooks/useGridBoxInputValidation';
import { useGetProgram } from '../../../../hooks/useProgram';
import { IenumKey } from '../../../../hooks/useTranslation';
import { displayAmountCurrying, importantStyle, removeEnumKeysByValue } from '../../../../utils';
import { EnumBoolean } from '../../../../utils/constant';
import { useZusDialogStore } from '../../../../zustand/store';
import { IapplicableProgram } from '../../CustomerApplication/config';
import { IapplicationDetail } from '../../CustomerList/config';
import {
  apiObj as api,
  IdetailsApiRes,
  ItableApiRes,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
} from '../config';

export type CustomerApplicationTKVal = (typeof TK)[keyof typeof TK];
export type CustomerApplicationTKKey = keyof typeof TK;
export type CustomerApplicationField = Partial<Record<CustomerApplicationTKKey, string>>;

type IdialogContent<T> = T extends undefined
  ? {
    mode: 'readonly';
    generalDetail: Omit<ItableApiRes, 'customer_application_approvals'> | undefined;
    applicationAnswers: IdetailsApiRes | undefined;
    hasEditBtn?: undefined;
    initFields?: undefined;
    createApplicationData?: undefined;
  }
  : keyof T extends CustomerApplicationTKKey
  ? {
    initFields: CustomerApplicationField;
  } & (
    | {
      mode: 'edit';
      generalDetail:
      | IapplicationDetail
      | undefined
      | Omit<ItableApiRes, 'customer_application_approvals'>;
      applicationAnswers: IdetailsApiRes | undefined;
      hasEditBtn?: { editEnabled: boolean };
      createApplicationData?: undefined;
    }
    | {
      mode: 'create';
      createApplicationData?: {
        customerId: string | undefined;
        emailAddress: string | undefined;
        phoneCountryCode: string | undefined;
        phoneNumber: string | undefined;
      };

      generalDetail?:
      | IapplicationDetail
      | undefined
      | Omit<ItableApiRes, 'customer_application_approvals'>;
      applicationAnswers?: IdetailsApiRes | undefined;
      hasEditBtn?: undefined;
      source?: number;
    }
  )
  : never;

type ReturnType<T> = T extends undefined
  ? {
    labelElePairArrWithoutAddressSection: Array<[CustomerApplicationTKVal, JSX.Element]>[];
    addressSectionLabelElePairArr: Array<[CustomerApplicationTKVal, JSX.Element]>[];
  }
  : {
    getValidationMessage: () => string | undefined;
    labelElePairArrWithoutAddressSection: Array<[CustomerApplicationTKVal, JSX.Element]>[];
    addressSectionLabelElePairArr: Array<[CustomerApplicationTKVal, JSX.Element]>[];
    setIsSameAddress: Dispatch<SetStateAction<boolean>>;
    isSameAddress: boolean;
    setFields: Dispatch<SetStateAction<Partial<Record<CustomerApplicationTKKey, string>>>>;
    fields: T;
    setApplicablePrograms: Dispatch<SetStateAction<IapplicableProgram[] | undefined>>;
  };

export default function useOperationDialogContent<T = undefined>(props: IdialogContent<T>) {
  const { mode, generalDetail, applicationAnswers, hasEditBtn, initFields, createApplicationData } =
    props;

  const [fields, setFields] = useState<Partial<Record<CustomerApplicationTKKey, string>>>(
    initFields || {}
  );

  const { te, tb, tcc, tc, t, tqt, translate } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();

  const displayAmount = displayAmountCurrying(0, 2);
  const zusDialog = useZusDialogStore();
  const rowSource = Number(zusDialog?.meta?.rowData?.source);

  const { validateGridBoxInput } = useGridBoxInputValidation();

  const {
    EnumProgramCurrency,
    EnumAllProgram,
    EnumProgramMaxCreditLimitList,
    EnumProgramCreditLimitList,
  } = useGetProgram();
  const [isSameAddress, setIsSameAddress] = useState(false);
  const isCreateModeWithDetails = mode === 'create' && !!applicationAnswers && !!generalDetail;
  useEffect(() => {
    if (!isSameAddress) {
      return;
    }

    setFields((state) => ({
      ...state,
      deliveryAddressLine1: state.residentialAddressLine1,
      deliveryAddressLine2: state.residentialAddressLine2,
      deliveryAddressLine3: state.residentialAddressLine3,
      deliveryPostalCode: state.residentialPostalCode,
      deliveryCity: state.residentialCity,
      deliveryCountry: state.residentialCountry,
    }));
  }, [isSameAddress]);

  const [applicablePrograms, setApplicablePrograms] = useState<IapplicableProgram[]>();

  useEffect(() => {
    const customerId = generalDetail?.customerId || createApplicationData?.customerId;

    if (!customerId || mode !== 'create') {
      return;
    }

    api.applicableProgram({ customerId, includePending: !!hasEditBtn }).then((res) => {
      if (!res) {
        return;
      }

      setApplicablePrograms(res);
    });
  }, [generalDetail?.customerId, createApplicationData?.customerId]);

  const displayPhoneNumber = useMemo(() => {
    if (createApplicationData?.phoneCountryCode && createApplicationData?.phoneNumber) {
      return createApplicationData.phoneCountryCode + ' ' + createApplicationData.phoneNumber;
    }

    if (!generalDetail?.phoneCountryCode || !generalDetail?.phoneNumber) {
      return '';
    }

    return generalDetail?.phoneCountryCode + ' ' + generalDetail?.phoneNumber;
  }, [
    generalDetail?.phoneCountryCode,
    generalDetail?.phoneNumber,
    createApplicationData?.phoneCountryCode,
    createApplicationData?.phoneNumber,
  ]);

  const onChange =
    (fieldName: CustomerApplicationTKKey) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
        if (!setFields) {
          return;
        }

        const value =
          fieldName === 'lastName' || fieldName === 'firstName'
            ? e.target.value.toUpperCase()
            : e.target.value;

        setFields((prev) => ({ ...prev, [fieldName]: value }));
      };

  const currency = generalDetail && EnumProgramCurrency[generalDetail.programName];

  const displayIsLatestVersion = tb(generalDetail?.isLatestVersion);
  const displayIsUsCitizen = tb(applicationAnswers?.usCitizenship);
  const displayIsAuthorisedTo3rdParty = tb(applicationAnswers?.authorizedToThirdParty);

  const FreeTextInputElement = useCallback(
    ({
      fieldName,
      sx,
      isDisabled,
      isOptional,
      customisedOnChange,
    }: {
      fieldName: CustomerApplicationTKKey;
      sx?: SxProps;
      isDisabled?: boolean;
      isOptional?: boolean;
      customisedOnChange?: (
        fieldName: CustomerApplicationTKKey
      ) => (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
      ) => void;
    }) => {
      return (
        <MpTextField
          label={tc(isOptional ? 'optional_field' : 'phInputField', {
            fieldName: t(TK[fieldName]),
          })}
          value={fields[fieldName]}
          onChange={(e) => {
            if (customisedOnChange) {
              return customisedOnChange(fieldName)(e);
            }

            onChange(fieldName)(e);
          }}
          disabled={isDisabled}
          sx={{ width: importantStyle('96%'), ...sx }}
        />
      );
    },
    [fields]
  );
  const EnglishOnlyInputElement = useCallback(
    ({
      fieldName,
      sx,
      isDisabled,
      isOptional,
      customisedOnChange,
    }: {
      fieldName: CustomerApplicationTKKey;
      sx?: SxProps;
      isDisabled?: boolean;
      isOptional?: boolean;
      customisedOnChange?: (
        fieldName: CustomerApplicationTKKey
      ) => (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
      ) => void;
    }) => {
      return (
        <MpTextFieldEnglishOnly
          label={tc(isOptional ? 'optional_field' : 'phInputField', {
            fieldName: t(TK[fieldName]),
          })}
          value={fields[fieldName]}
          onChange={(e) => {
            if (customisedOnChange) {
              return customisedOnChange(fieldName)(e);
            }

            onChange(fieldName)(e);
          }}
          disabled={isDisabled}
          isAllowedSpecialChar
          sx={{ width: importantStyle('96%'), ...sx }}
        />
      );
    },
    [fields]
  );
  const NumberInputElement = useCallback(
    ({
      fieldName,
      decimalCount,
      integerOnly,
      sx,
      isDisabled,
      isOptional,
    }: {
      fieldName: CustomerApplicationTKKey;
      decimalCount?: number;
      integerOnly?: boolean;
      sx?: SxProps;
      isDisabled?: boolean;
      isOptional?: boolean;
    }) => {
      return (
        <MpTextFieldNumberOnly
          label={tc(isOptional ? 'optional_field' : 'phInputField', {
            fieldName: t(TK[fieldName]),
          })}
          value={fields[fieldName]}
          onChange={onChange(fieldName)}
          decimalCount={decimalCount}
          integerOnly={integerOnly}
          sx={{ width: importantStyle('96%'), ...sx }}
          disabled={isDisabled}
        />
      );
    },
    [fields]
  );
  const SingleSelectionElement = useCallback(
    ({
      fieldName,
      enumData,
      nameFn,
      isDisabled,
      isOptional,
      sx,
      customisedOnChange,
    }: {
      fieldName: CustomerApplicationTKKey;
      enumData: Record<string, any>;
      nameFn?: (str: string) => string;
      isDisabled?: boolean;
      isOptional?: boolean;
      sx?: { formControl?: SxProps; select?: SxProps };
      customisedOnChange?: (
        fieldName: CustomerApplicationTKKey
      ) => (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
      ) => void;
    }) => {
      return (
        <SingleSelection
          // key={'selection' + '-' + fieldName}
          label={tc(isOptional ? 'optional_field' : 'phSelection', {
            fieldName: t(TK[fieldName]),
          })}
          value={fields[fieldName] || ''}
          onChange={(e) => {
            if (customisedOnChange) {
              return customisedOnChange(fieldName)(e);
            }

            onChange(fieldName)(e);
          }}
          enumData={enumData}
          clearSelect={() => { }}
          nameFn={nameFn}
          disabled={isDisabled}
          sx={{
            formControl: { width: importantStyle('96%'), ...sx?.formControl },
            select: sx?.select,
          }}
          isNoSorting
        />
      );
    },
    [fields, applicablePrograms]
  );

  const checkFieldExists = (fieldName: CustomerApplicationTKKey) => fields[fieldName] !== undefined;

  const onDeselectResetInputField =
    (
      fieldName: CustomerApplicationTKKey,
      resetConfig: { deselectedOptions: number[]; inputField: CustomerApplicationTKKey }[]
    ) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
        resetConfig.forEach(({ deselectedOptions, inputField }) => {
          deselectedOptions.forEach((value) => {
            if (Number(e.target.value) !== value && checkFieldExists(fieldName)) {
              setFields((prev) => ({ ...prev, [inputField]: '' }));
            }
          });
        });

        onChange(fieldName)(e);
      };

  const DateSelectionElement = useCallback(
    ({
      fieldName,
      isDisabled,
      isOptional,
      beforeToday,
      afterToday,
      isDateOfBirthField,
    }: {
      fieldName: CustomerApplicationTKKey;
      isDisabled?: boolean;
      isOptional?: boolean;
      beforeToday?: boolean;
      afterToday?: boolean;
      isDateOfBirthField?: boolean;
    }) => {
      const fieldValue = fields[fieldName];
      const timeValue = fieldValue ? new Date(fieldValue) : null;
      return (
        <TimePicker
          // key={'date-picker' + '-' + fieldName}
          label={tc(isOptional ? 'optional_field' : 'phSelection', {
            fieldName: t(TK[fieldName]),
          })}
          timeValue={timeValue}
          setTimeValue={(dateVal: Date | null) => {
            if (!setFields) {
              return;
            }
            const year = dateVal && String(dateVal.getFullYear());
            const month = dateVal && String(dateVal.getMonth() + 1);
            const date = dateVal?.getDate();

            const isoTime = dateVal && subMinutes(dateVal, dateVal.getTimezoneOffset());

            setFields((prev) => ({
              ...prev,
              [fieldName]: isoTime?.toISOString().split('T')[0] || '',
            }));
          }}
          type={'date'}
          sx={{ width: importantStyle('96%') }}
          disabled={isDisabled}
          minEndTime={afterToday ? new Date() : undefined}
          maxStartTime={
            beforeToday ? new Date() : isDateOfBirthField ? subYears(new Date(), 18) : undefined
          }
          defaultCalendarMonth={isDateOfBirthField ? subYears(new Date(), 18) : undefined}
          views={['year', 'month', 'day']}
        />
      );
    },
    [fields]
  );

  const EnumApplicableProgramNames = useMemo(
    () =>
      applicablePrograms?.reduce((acc: Record<string, string>, { programName, source }) => {
        if (source === rowSource) {
          return {
            ...acc,
            [programName]: programName,
          };
        }

        return acc;
      }, {}) || {},
    [applicablePrograms, rowSource]
  );

  const EnumApplicableReferralCode = useMemo(() => {
    const program = applicablePrograms?.find(
      ({ programName }) => fields.programName === programName
    );

    const referralCodes =
      program?.referralCodes?.reduce((acc, { referralCode, questionType, source }) => {
        const isCorrectQuestionType = questionType === 1;
        const isCorrectSource = source === rowSource;

        if (isCorrectQuestionType && isCorrectSource) {
          return { ...acc, [referralCode]: referralCode };
        }
        return acc;
      }, {}) || {};

    return referralCodes;
  }, [fields.programName, applicablePrograms, rowSource]);

  const countryCodeEnum = useMemo(
    () =>
      COUNTRY_CODE_LIST.reduce((acc: Record<string, any>, { isoCode3, country }) => {
        return { ...acc, [country]: isoCode3 };
      }, {}),
    []
  );

  const displayMaxCreditLimit = useMemo(() => {
    const selectedProgramInfo = applicablePrograms?.find(
      ({ programName }) => fields.programName === programName
    );

    const generalDetialMaxCreditLimit =
      mode === 'create' ? undefined : generalDetail?.maxCreditLimit;

    const rawMaxCreditLimit =
      selectedProgramInfo?.maxCreditLimit || generalDetialMaxCreditLimit || '';

    const homeCurrency = selectedProgramInfo?.homeCurrency || currency;

    return displayAmountWithCurrency(displayAmount(rawMaxCreditLimit), homeCurrency);
  }, [fields.programName, generalDetail]);

  const displayCreditLimit = useMemo(() => {
    const selectedProgramInfo = applicablePrograms?.find(
      ({ programName }) => fields.programName === programName
    );

    const generalDetialCreditLimit = mode === 'create' ? undefined : generalDetail?.creditLimit;

    const rawCreditLimit = selectedProgramInfo?.creditLimit || generalDetialCreditLimit || '';

    const homeCurrency = selectedProgramInfo?.homeCurrency || currency;

    return displayAmountWithCurrency(displayAmount(rawCreditLimit), homeCurrency);
  }, [fields.programName, generalDetail]);

  const displayQuestionType = useMemo(() => {
    if (mode === 'create') {
      const program = applicablePrograms?.find((p) => p.programName === fields.programName);
      const referral = program?.referralCodes.find((r) => r.referralCode === fields.referralCode);
      return referral ? tqt(EnumQuestionType[Number(referral.questionType)]) : '';
    }
    return generalDetail?.applicationReferralCode?.questionType
      ? tqt(EnumQuestionType[Number(generalDetail?.applicationReferralCode?.questionType)])
      : '';
  }, [fields.referralCode, fields.programName, applicablePrograms, generalDetail, tqt]);

  const displayProgramAgentId = useMemo(() => {
    if (mode === 'create') {
      const program = applicablePrograms?.find((p) => p.programName === fields.programName);
      const referral = program?.referralCodes.find((r) => r.referralCode === fields.referralCode);
      return referral ? referral.programAgentId : '';
    }
    return generalDetail?.programAgentId ? generalDetail?.programAgentId : '';
  }, [fields.referralCode, fields.programName, applicablePrograms, generalDetail]);

  const displayDistributorAgentId = useMemo(() => {
    if (mode === 'create') {
      const program = applicablePrograms?.find((p) => p.programName === fields.programName);
      const referral = program?.referralCodes.find((r) => r.referralCode === fields.referralCode);
      return referral ? referral.distributorAgentId : '';
    }
    return generalDetail?.distributorAgentId ? generalDetail?.distributorAgentId : '';
  }, [fields.referralCode, fields.programName, applicablePrograms, generalDetail]);

  const sourceDisplayValue = useMemo(() => {
    if (mode === 'create') {
      const program = applicablePrograms?.find((p) => p.programName === fields.programName);
      const referral = program?.referralCodes.find((r) => r.referralCode === fields.referralCode);

      if (!referral) return '';

      return getSourceDisplayValue(referral.source, translate);
    }

    return getSourceDisplayValue(generalDetail?.applicationReferralCode?.source, translate);
  }, [fields.referralCode, fields.programName, applicablePrograms, generalDetail]);

  const detailsArr = [
    ['applicationNumber', { readonly: <Box>{generalDetail?.applicationNumber}</Box> }],
    [
      'applicationType',
      {
        readonly: (
          <Box>
            {generalDetail?.type && te(EnumCustomerApplicationType[generalDetail.type] as IenumKey)}
          </Box>
        ),
      },
    ],
    ['versionNumber', { readonly: <Box>{generalDetail?.version}</Box> }],
    ['customerNumber', { readonly: <Box>{generalDetail?.customerNumber}</Box> }],
    ['customerLevel', { readonly: <Box>{generalDetail?.customerLevel}</Box> }],
    ['latestVersion', { readonly: <Box>{displayIsLatestVersion}</Box> }],
    [
      'programName',
      {
        readonly: <Box>{generalDetail?.programName}</Box>,
        editable: SingleSelectionElement({
          fieldName: 'programName',
          enumData: EnumApplicableProgramNames,
          nameFn: (name) => name,
          customisedOnChange: (fieldName) => (e) => {
            if (checkFieldExists('referralCode')) {
              setFields((prev) => ({ ...prev, referralCode: '' }));
            }
            onChange(fieldName)(e);
          },
        }),
      },
    ],
    [
      'referralCode',
      {
        readonly: <Box>{generalDetail?.referralCode}</Box>,
        editable: SingleSelectionElement({
          fieldName: 'referralCode',
          enumData: EnumApplicableReferralCode,
          nameFn: (name) => name,
        }),
      },
    ],
    [
      'questionType',
      {
        readonly: <Box>{displayQuestionType}</Box>,
      },
    ],
    [
      'programAgentId',
      {
        readonly: <Box>{displayProgramAgentId}</Box>,
      },
    ],
    [
      'distributorAgentId',
      {
        readonly: <Box>{displayDistributorAgentId}</Box>,
      },
    ],
    [
      'maxCreditLimit',
      {
        readonly: <Box>{displayMaxCreditLimit}</Box>,
      },
    ],
    [
      'creditLimit',
      {
        readonly: <Box>{displayCreditLimit}</Box>,
      },
    ],
    [
      'source',
      // Customer List - UpdateInfo + CreateCustomerApplication
      // Customer Application - Details + ManualReviewIDV + ApproveReject
      {
        readonly: <Box>{sourceDisplayValue}</Box>,
      },
    ],
  ].filter(([fieldName]) => {
    if (mode === 'create') {
      return fieldName === 'programName' ||
        fieldName === 'referralCode' ||
        fieldName === 'creditLimit' ||
        fieldName === 'questionType' ||
        fieldName === 'maxCreditLimit' ||
        fieldName === 'source'
        ? true
        : false;
    }

    return true;
  }) as Array<[CustomerApplicationTKKey, { readonly: JSX.Element; editable?: JSX.Element }]>;

  const filteredDetailsArr = detailsArr.filter(([label, eleObj]) => {
    if (generalDetail) {
      return true;
    }
    return (
      eleObj.editable ||
      label === 'maxCreditLimit' ||
      label === 'creditLimit' ||
      label === 'questionType' ||
      label === 'source'
    );
  });

  const personalDetailsArr: Array<
    [CustomerApplicationTKKey, { readonly: JSX.Element; editable?: JSX.Element }]
  > = [
      [
        'customerId',
        { readonly: <Box>{createApplicationData?.customerId || generalDetail?.customerId}</Box> },
      ],
      [
        TK.title,
        {
          readonly: (
            <Box>{applicationAnswers && te(EnumTitle[applicationAnswers.title] as IenumKey)}</Box>
          ),
          editable: SingleSelectionElement({
            fieldName: 'title',
            enumData: removeEnumKeysByValue(EnumTitle, [EnumTitle.None]),
          }),
        },
      ],
      [
        'firstName',
        {
          readonly: <Box>{generalDetail?.firstName}</Box>,
          editable: EnglishOnlyInputElement({
            fieldName: 'firstName',
          }),
        },
      ],
      [
        'lastName',
        {
          readonly: <Box>{generalDetail?.lastName}</Box>,
          editable: EnglishOnlyInputElement({
            fieldName: 'lastName',
          }),
        },
      ],
      [
        'chineseName',
        {
          readonly: <Box>{applicationAnswers?.chineseName}</Box>,
          editable: FreeTextInputElement({
            fieldName: 'chineseName',
            isOptional: true,
          }),
        },
      ],
      [
        'alias',
        {
          readonly: <Box>{applicationAnswers?.alias}</Box>,
          editable: FreeTextInputElement({
            fieldName: 'alias',
            isOptional: true,
          }),
        },
      ],
      [
        'gender',
        {
          readonly: <Box>{applicationAnswers?.gender}</Box>,
          editable: SingleSelectionElement({
            fieldName: 'gender',
            enumData: EnumGender,
          }),
        },
      ],
      [
        'dateOfBirth',
        {
          readonly: <Box>{generalDetail?.dateOfBirth}</Box>,
          editable: DateSelectionElement({
            fieldName: 'dateOfBirth',
            isDateOfBirthField: true,
          }),
        },
      ],
      [
        'nationality',
        {
          readonly: <Box>{tcc(String(applicationAnswers?.nationality))}</Box>,
          editable: SingleSelectionElement({
            fieldName: 'nationality',
            enumData: countryCodeEnum,
            nameFn: (name) => name,
          }),
        },
      ],
      [
        'emailAddress',
        {
          readonly: <Box>{createApplicationData?.emailAddress || generalDetail?.email}</Box>,
        },
      ],
      [
        'phoneNumber',
        {
          readonly: <Box>{displayPhoneNumber}</Box>,
        },
      ],
      [
        'identificationDocumentType',
        {
          readonly: (
            <Box>
              {generalDetail?.idType && te(EnumDocumentType[generalDetail.idType] as IenumKey)}
            </Box>
          ),
          editable: SingleSelectionElement({
            fieldName: 'identificationDocumentType',
            enumData: removeEnumKeysByValue(EnumDocumentType, [
              EnumDocumentType.DrivingLicence,
              EnumDocumentType.ShareCode,
              EnumDocumentType.SocialInsurance,
              EnumDocumentType.Ssn,
              EnumDocumentType.TaxId,
              EnumDocumentType.VoterId,
            ]),
            customisedOnChange: (field) => (e) => {
              onDeselectResetInputField(field, [
                {
                  deselectedOptions: [EnumDocumentType.Others],
                  inputField: 'identificationDocumentTypeOthers',
                },
                {
                  deselectedOptions: [
                    EnumDocumentType.Others,
                    EnumDocumentType.Passport,
                    EnumDocumentType.IdentityCard,
                  ],
                  inputField: 'placeOfIssue',
                },
              ])(e);

              if (
                // checkFieldValWithEnumOption({})
                Number(e.target.value) === EnumDocumentType.IdentityCard &&
                checkFieldExists('identificationDocumentTypeOthers')
              ) {
                setFields((prev) => ({ ...prev, placeOfIssue: 'HKG' }));
              }

              if (
                Number(e.target.value) === EnumDocumentType.ChineseId &&
                checkFieldExists('identificationDocumentTypeOthers')
              ) {
                setFields((prev) => ({ ...prev, placeOfIssue: 'CHN' }));
              }
            },
          }),
        },
      ],
      [
        'identificationDocumentTypeOthers',
        {
          readonly: <Box>{applicationAnswers?.idTypeOthers}</Box>,
          editable: FreeTextInputElement({
            fieldName: 'identificationDocumentTypeOthers',
            isDisabled: Number(fields.identificationDocumentType) !== EnumDocumentType.Others,
          }),
        },
      ],
      [
        'placeOfIssue',
        {
          readonly: <Box>{generalDetail?.idIssuedBy && tcc(generalDetail.idIssuedBy)}</Box>,
          editable: SingleSelectionElement({
            fieldName: 'placeOfIssue',
            enumData: countryCodeEnum,
            nameFn: (name) => name,
            isDisabled:
              Number(fields.identificationDocumentType) === EnumDocumentType.IdentityCard ||
              Number(fields.identificationDocumentType) === EnumDocumentType.ChineseId ||
              (Number(fields.identificationDocumentType) !== EnumDocumentType.Passport &&
                Number(fields.identificationDocumentType) !== EnumDocumentType.Others),
          }),
        },
      ],
      [
        'identificationNumber',
        {
          readonly: generalDetail?.applicationNumber ? (
            <TogglableIdNumber
              permissionKey={PermissionKey.Details.ViewIdNumber}
              apiFn={async () =>
                await api.decryptIdNumber({ applicationNumber: generalDetail.applicationNumber })
              }
            />
          ) : (
            <></>
          ),
          editable: FreeTextInputElement({
            fieldName: 'identificationNumber',
          }),
        },
      ],
      [
        'dateOfIssue',
        {
          readonly: <Box>{applicationAnswers?.idDateOfIssue}</Box>,
          editable: DateSelectionElement({
            fieldName: 'dateOfIssue',
            beforeToday: true,
          }),
        },
      ],
      [
        'dateOfExpiry',
        {
          readonly: <Box>{applicationAnswers?.idDateOfExpiry}</Box>,
          editable: DateSelectionElement({
            fieldName: 'dateOfExpiry',
            afterToday: true,
            isOptional: true,
          }),
        },
      ],
      [
        'usCitizenship',
        {
          readonly: <Box>{displayIsUsCitizen}</Box>,
          editable: SingleSelectionElement({ fieldName: 'usCitizenship', enumData: EnumBoolean }),
        },
      ],
      [
        'maritalStatus',
        {
          readonly: (
            <Box>
              {applicationAnswers &&
                te(EnumMaritalStatus[applicationAnswers.maritalStatus] as IenumKey)}
            </Box>
          ),
          editable: SingleSelectionElement({
            fieldName: 'maritalStatus',
            enumData: EnumMaritalStatus,
          }),
        },
      ],
      [
        'educationalStatus',
        {
          readonly: (
            <Box>
              {applicationAnswers &&
                te(EnumEducationLevel[applicationAnswers.educationLevel] as IenumKey)}
            </Box>
          ),
          editable: SingleSelectionElement({
            fieldName: 'educationalStatus',
            enumData: EnumEducationLevel,
            customisedOnChange: (field) =>
              onDeselectResetInputField(field, [
                {
                  deselectedOptions: [EnumEducationLevel.Others],
                  inputField: 'educationalStatusOthers',
                },
              ]),
          }),
        },
      ],
      [
        'educationalStatusOthers',
        {
          readonly: <Box>{applicationAnswers?.educationLevelOthers}</Box>,
          editable: FreeTextInputElement({
            fieldName: 'educationalStatusOthers',
            isDisabled: Number(fields.educationalStatus) !== EnumEducationLevel.Others,
          }),
        },
      ],
      [
        'purposeForApplying',
        {
          readonly: (
            <Box>
              {applicationAnswers &&
                te(EnumPurposeApplying[applicationAnswers.purposeForApplying] as IenumKey)}
            </Box>
          ),
          editable: SingleSelectionElement({
            fieldName: 'purposeForApplying',
            enumData: EnumPurposeApplying,
            customisedOnChange: (field) =>
              onDeselectResetInputField(field, [
                { deselectedOptions: [EnumPurposeApplying.Others], inputField: 'otherPurpose' },
              ]),
          }),
        },
      ],
      [
        'otherPurpose',
        {
          readonly: <Box>{applicationAnswers?.purposeForApplyingOthers}</Box>,
          editable: FreeTextInputElement({
            fieldName: 'otherPurpose',
            isDisabled: Number(fields.purposeForApplying) !== EnumPurposeApplying.Others,
          }),
        },
      ],
      [
        'isAuthorizedByYou',
        {
          readonly: <Box>{displayIsAuthorisedTo3rdParty}</Box>,
          editable: SingleSelectionElement({ fieldName: 'isAuthorizedByYou', enumData: EnumBoolean }),
        },
      ],
    ];

  const getIsNotWorkingGroup = (fieldValue: number) =>
    fieldValue === EnumEmploymentStatus.Student ||
    fieldValue === EnumEmploymentStatus.Housewife ||
    fieldValue === EnumEmploymentStatus.Retired ||
    fieldValue === EnumEmploymentStatus.Unemployed;

  const isNotWorkingGroup = getIsNotWorkingGroup(Number(fields.employmentStatus));

  const occupationalDetailsArr: Array<
    [CustomerApplicationTKKey, { readonly: JSX.Element; editable: JSX.Element }]
  > = [
      [
        'employmentStatus',
        {
          readonly: (
            <Box>
              {te(EnumEmploymentStatus[Number(applicationAnswers?.employmentStatus)] as IenumKey)}
            </Box>
          ),
          editable: SingleSelectionElement({
            fieldName: 'employmentStatus',
            enumData: EnumEmploymentStatus,
            customisedOnChange: (fieldName) => (e) => {
              const selectedNumVal = Number(e.target.value);
              const relatedFields = [
                'companyName',
                'jobTitle',
                'jobTitleOthers',
                'businessNatureOrIndustry',
                'otherIndustry',
                'employmentStartDate',
                'officeTelephoneNumber',
                'monthlySalaryInHkd',
              ] as const;

              if (getIsNotWorkingGroup(selectedNumVal)) {
                relatedFields.forEach((fieldName) => {
                  if (checkFieldExists(fieldName)) {
                    setFields((prev) => ({ ...prev, [fieldName]: '' }));
                  }
                });
              }
              onChange(fieldName)(e);
            },
          }),
        },
      ],
      [
        'companyName',
        {
          readonly: <Box>{applicationAnswers?.companyName}</Box>,
          editable: FreeTextInputElement({
            fieldName: 'companyName',
            isDisabled: isNotWorkingGroup,
          }),
        },
      ],
      [
        'jobTitle',
        {
          readonly: (
            <Box>
              {applicationAnswers && te(EnumJobTitle[applicationAnswers.jobTitle] as IenumKey)}
            </Box>
          ),
          editable: SingleSelectionElement({
            fieldName: 'jobTitle',
            enumData: EnumJobTitle,
            isDisabled: isNotWorkingGroup,
            customisedOnChange: (field) =>
              onDeselectResetInputField(field, [
                { deselectedOptions: [EnumJobTitle.Others], inputField: 'jobTitleOthers' },
              ]),
          }),
        },
      ],
      [
        'jobTitleOthers',
        {
          readonly: <Box>{applicationAnswers?.jobTitleOthers}</Box>,
          editable: FreeTextInputElement({
            fieldName: 'jobTitleOthers',
            isDisabled: Number(fields.jobTitle) !== EnumJobTitle.Others || isNotWorkingGroup,
          }),
        },
      ],
      [
        'businessNatureOrIndustry',
        {
          readonly: (
            <Box>
              {applicationAnswers &&
                te(EnumBusinessIndustry[applicationAnswers.industry] as IenumKey)}
            </Box>
          ),
          editable: SingleSelectionElement({
            fieldName: 'businessNatureOrIndustry',
            enumData: EnumBusinessIndustry,
            isDisabled: isNotWorkingGroup,
            customisedOnChange: (field) =>
              onDeselectResetInputField(field, [
                { deselectedOptions: [EnumBusinessIndustry.Others], inputField: 'otherIndustry' },
              ]),
          }),
        },
      ],
      [
        'otherIndustry',
        {
          readonly: <Box>{applicationAnswers?.industryOthers}</Box>,
          editable: FreeTextInputElement({
            fieldName: 'otherIndustry',
            isDisabled:
              Number(fields.businessNatureOrIndustry) !== EnumBusinessIndustry.Others ||
              isNotWorkingGroup,
          }),
        },
      ],
      [
        'employmentStartDate',
        {
          readonly: <Box>{applicationAnswers?.employmentStartDate}</Box>,
          editable: DateSelectionElement({
            fieldName: 'employmentStartDate',
            isDisabled: isNotWorkingGroup,
            beforeToday: true,
          }),
        },
      ],
      [
        'officeTelephoneNumber',
        {
          readonly: <Box>{applicationAnswers?.officeTelephoneNumber}</Box>,
          editable: NumberInputElement({
            fieldName: 'officeTelephoneNumber',
            integerOnly: true,
            isDisabled: isNotWorkingGroup,
          }),
        },
      ],
      [
        'monthlySalaryInHkd',
        {
          readonly: (
            <Box>
              {applicationAnswers &&
                displayAmountWithCurrency(displayAmount(applicationAnswers.monthlySalaryHKD), 'HKD')}
            </Box>
          ),
          editable: NumberInputElement({
            fieldName: 'monthlySalaryInHkd',
            isDisabled: isNotWorkingGroup,
            decimalCount: 2,
          }),
        },
      ],
      [
        'otherMonthlyIncome',
        {
          readonly: (
            <Box>
              {applicationAnswers &&
                displayAmountWithCurrency(displayAmount(applicationAnswers.otherIncomeHKD), 'HKD')}
            </Box>
          ),
          editable: NumberInputElement({
            fieldName: 'otherMonthlyIncome',
            decimalCount: 2,
            isOptional: true,
          }),
        },
      ],
    ];

  const redirectHandler = () =>
    window.open(
      `${process.env.REACT_APP_ONFIDO_REDIRECT_URL}${generalDetail?.kycRef?.workflowRunId}`,
      '_blank',
      'noopener,noreferrer'
    );

  const residentialDetailsArr: Array<
    [CustomerApplicationTKKey, { readonly: JSX.Element; editable: JSX.Element }]
  > = [
      [
        'residentialStatus',
        {
          readonly: (
            <Box>
              {applicationAnswers &&
                te(EnumResidentialStatus[applicationAnswers.residentialStatus] as IenumKey)}
            </Box>
          ),
          editable: SingleSelectionElement({
            fieldName: 'residentialStatus',
            enumData: EnumResidentialStatus,
            customisedOnChange: (field) =>
              onDeselectResetInputField(field, [
                { deselectedOptions: [EnumResidentialStatus.Others], inputField: 'otherStatus' },
              ]),
          }),
        },
      ],
      [
        'otherStatus',
        {
          readonly: <Box>{applicationAnswers?.residentialStatusOthers}</Box>,
          editable: FreeTextInputElement({
            fieldName: 'otherStatus',
            isDisabled: Number(fields.residentialStatus) !== EnumResidentialStatus.Others,
          }),
        },
      ],
      [
        'residentialTelephoneNumber',
        {
          readonly: <Box>{applicationAnswers?.residentialTelephoneNumber}</Box>,
          editable: NumberInputElement({
            fieldName: 'residentialTelephoneNumber',
            integerOnly: true,
          }),
        },
      ],
    ];

  const idvDetailsArr: Array<[string, JSX.Element]> = [
    [
      TK.idvStatus,
      <Box>
        {generalDetail?.kycIdvStatus && te(EnumIdvStatus[generalDetail.kycIdvStatus] as IenumKey)}
      </Box>,
    ],
    [
      TK.idvMethod,
      <Box>
        {generalDetail?.kycIdvMethod && te(EnumIdvMethod[generalDetail.kycIdvMethod] as IenumKey)}
      </Box>,
    ],
    [TK.idvManuallyVerifiedBy, <Box>{generalDetail?.kycIdvManuallyVerifiedBy}</Box>],
    [TK.documentStatus, <Box>{generalDetail?.kycDocumentStatus}</Box>],
    [TK.documentSubStatus, <Box>{generalDetail?.kycDocumentSubStatus}</Box>],
    [TK.facialSimilarityStatus, <Box>{generalDetail?.kycFacialStatus}</Box>],
    [TK.facialSimilaritySubStatus, <Box>{generalDetail?.kycFacialSubStatus}</Box>],
    [TK.watchListStatus, <Box>{generalDetail?.kycWatchListStatus}</Box>],
    [TK.watchListSubStatus, <Box>{generalDetail?.kycWatchListSubStatus}</Box>],
    [TK.applicantId, <Box>{generalDetail?.kycRef?.applicantId}</Box>],
    [
      TK.workflowRunId,
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
        <Box>{generalDetail?.kycRef?.workflowRunId}</Box>
        {generalDetail?.kycRef?.workflowRunId && (
          <OpIconButton
            title={''}
            svgUrl={OpRedirect}
            size="1.2rem"
            sxBox={{ marginLeft: '4px' }}
            onClick={redirectHandler}
          />
        )}
      </Box>,
    ],
    [TK.remarks, <Box>{generalDetail?.kycRemarks}</Box>],
  ];

  const getAddressSectionArrByPrefix: (
    prefix: 'residential' | 'delivery'
  ) => Array<[CustomerApplicationTKKey, { readonly: JSX.Element; editable: JSX.Element }]> = (
    prefix
  ) => {
      const addressSectionOnChange =
        (key: CustomerApplicationTKKey) =>
          (e: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (!setFields) {
              return;
            }

            setFields((prev) => {
              const deliveryKey = key.replace('residential', 'delivery') as CustomerApplicationTKKey;

              if (isSameAddress && checkFieldExists(deliveryKey)) {
                return {
                  ...prev,
                  [key]: e.target.value,
                  [deliveryKey]: e.target.value,
                };
              }

              return {
                ...prev,
                [key]: e.target.value,
              };
            });
          };

      const isDisabled = isSameAddress && prefix === 'delivery';

      const labelEleObjArr: Array<
        [CustomerApplicationTKKey, { readonly: JSX.Element; editable: JSX.Element }]
      > = [
          [
            `${prefix}AddressLine1`,
            {
              readonly: <Box>{applicationAnswers?.[`${prefix}AddressLine1`]}</Box>,
              editable: FreeTextInputElement({
                fieldName: `${prefix}AddressLine1`,
                isDisabled,
                customisedOnChange: addressSectionOnChange,
              }),
            },
          ],
          [
            `${prefix}AddressLine2`,
            {
              readonly: <Box>{applicationAnswers?.[`${prefix}AddressLine2`]}</Box>,
              editable: FreeTextInputElement({
                fieldName: `${prefix}AddressLine2`,
                isDisabled,
                isOptional: true,
                customisedOnChange: addressSectionOnChange,
              }),
            },
          ],
          [
            `${prefix}AddressLine3`,
            {
              readonly: <Box>{applicationAnswers?.[`${prefix}AddressLine3`]}</Box>,
              editable: FreeTextInputElement({
                fieldName: `${prefix}AddressLine3`,
                isDisabled,
                isOptional: true,
                customisedOnChange: addressSectionOnChange,
              }),
            },
          ],
          [
            `${prefix}PostalCode`,
            {
              readonly: <Box>{applicationAnswers?.[`${prefix}PostalCode`]}</Box>,
              editable: FreeTextInputElement({
                fieldName: `${prefix}PostalCode`,
                isDisabled,
                customisedOnChange: addressSectionOnChange,
              }),
            },
          ],
          [
            `${prefix}City`,
            {
              readonly: <Box>{applicationAnswers?.[`${prefix}City`]}</Box>,
              editable: FreeTextInputElement({
                fieldName: `${prefix}City`,
                isDisabled,
                customisedOnChange: addressSectionOnChange,
              }),
            },
          ],
          [
            `${prefix}Country`,
            {
              readonly: (
                <Box>
                  {applicationAnswers?.[`${prefix}Country`] &&
                    tcc(applicationAnswers[`${prefix}Country`])}
                </Box>
              ),
              editable: SingleSelectionElement({
                fieldName: `${prefix}Country`,
                customisedOnChange: addressSectionOnChange,
                isDisabled,
                enumData: countryCodeEnum,
                nameFn: (name) => name,
              }),
            },
          ],
        ];

      return labelEleObjArr;
    };

  const transformLabelEleObjArr = ({
    labelEleObjArr,
    isForcedReadonly,
  }: {
    labelEleObjArr: Array<
      [CustomerApplicationTKKey, { readonly: JSX.Element; editable?: JSX.Element }]
    >;
    isForcedReadonly?: boolean;
  }) => {
    return labelEleObjArr.map(([TKKey, eleObj]) => [
      TK[TKKey],
      checkFieldExists(TKKey) && !isForcedReadonly ? eleObj.editable : eleObj.readonly,
    ]) as Array<[CustomerApplicationTKVal, JSX.Element]>;
  };

  const isForcedReadonly = hasEditBtn ? !hasEditBtn.editEnabled : false;

  const labelElePairArrWithoutIdvDetailArr = [
    filteredDetailsArr,
    personalDetailsArr,
    occupationalDetailsArr,
    residentialDetailsArr,
  ].map((labelEleObjArr) => transformLabelEleObjArr({ labelEleObjArr, isForcedReadonly }));

  const addressSectionElePairArrList = [
    getAddressSectionArrByPrefix('residential'),
    getAddressSectionArrByPrefix('delivery'),
  ].map((labelEleObjArr) => transformLabelEleObjArr({ labelEleObjArr, isForcedReadonly }));
  const getFlattenedEditableLabelEleArr = (
    editableLabelEleArrList: Array<[CustomerApplicationTKVal, JSX.Element]>[]
  ): Array<[CustomerApplicationTKVal, JSX.Element]> =>
    editableLabelEleArrList.reduce((acc: Array<[CustomerApplicationTKVal, JSX.Element]>, cur) => {
      acc.push(...cur);
      return acc;
    }, []);

  const flattenedEditableLabelEleArr = [
    ...getFlattenedEditableLabelEleArr(labelElePairArrWithoutIdvDetailArr),
    ...getFlattenedEditableLabelEleArr(addressSectionElePairArrList),
  ];

  const labelElePairArrWithoutAddressSection = generalDetail
    ? [...labelElePairArrWithoutIdvDetailArr, idvDetailsArr]
    : labelElePairArrWithoutIdvDetailArr;

  const getValidationConfig = (prefix: 'residential' | 'delivery'): Iconfig[] => {
    return flattenedEditableLabelEleArr
      .filter(
        ([label]) =>
          label.includes(`${prefix}`) &&
          label !== 'residential_status' &&
          label !== 'residential_telephone_number'
      )
      .map(([label]) => {
        if (label === `${prefix}_${TK.country}`) {
          return {
            fieldName: label,
            customMessage: tc('phSelection', { fieldName: t(`${label}_warning`) }),
          };
        }
        if (label === `${prefix}_${TK.addressLine2}` || label === `${prefix}_${TK.addressLine3}`) {
          return {
            fieldName: label,
            isSkipped: true,
          };
        }

        return {
          fieldName: label,
          customMessage: tc('phInputField', { fieldName: t(`${label}_warning`) }),
        };
      });
  };

  const idNumberLengthChecking = () => {
    const idNumber = fields.identificationNumber;

    if (idNumber && idNumber.length < 5) {
      return t('id_number_length_warning');
    }

    return undefined;
  };

  const getValidationMessage = () =>
    validateGridBoxInput(flattenedEditableLabelEleArr, [
      { fieldName: TK.chineseName, isSkipped: true },
      { fieldName: TK.alias, isSkipped: true },
      {
        fieldName: TK.identificationDocumentTypeOthers,
        isSkipped: Number(fields.identificationDocumentType) !== EnumDocumentType.Others,
      },
      {
        fieldName: TK.placeOfIssue,
        isSkipped:
          Number(fields.identificationDocumentType) === EnumDocumentType.IdentityCard ||
          (Number(fields.identificationDocumentType) !== EnumDocumentType.Passport &&
            Number(fields.identificationDocumentType) !== EnumDocumentType.Others),
      },
      { fieldName: TK.dateOfExpiry, isSkipped: true },
      {
        fieldName: TK.educationalStatusOthers,
        isSkipped: Number(fields.educationalStatus) !== EnumEducationLevel.Others,
      },
      {
        fieldName: TK.otherPurpose,
        isSkipped: Number(fields.purposeForApplying) !== EnumPurposeApplying.Others,
      },

      { fieldName: TK.companyName, isSkipped: isNotWorkingGroup },
      { fieldName: TK.jobTitle, isSkipped: isNotWorkingGroup },
      {
        fieldName: TK.jobTitleOthers,
        isSkipped: Number(fields.jobTitle) !== EnumJobTitle.Others || isNotWorkingGroup,
      },
      { fieldName: TK.businessNatureOrIndustry, isSkipped: isNotWorkingGroup },
      {
        fieldName: TK.otherIndustry,
        isSkipped:
          Number(fields.businessNatureOrIndustry) !== EnumBusinessIndustry.Others ||
          isNotWorkingGroup,
      },
      { fieldName: TK.employmentStartDate, isSkipped: isNotWorkingGroup },
      { fieldName: TK.officeTelephoneNumber, isSkipped: isNotWorkingGroup },
      { fieldName: TK.monthlySalaryInHkd, isSkipped: isNotWorkingGroup },
      { fieldName: TK.otherMonthlyIncome, isSkipped: true },
      {
        fieldName: TK.otherStatus,
        isSkipped: Number(fields.residentialStatus) !== EnumResidentialStatus.Others,
      },
      ...getValidationConfig('residential'),
      ...getValidationConfig('delivery'),
    ]) || idNumberLengthChecking();

  return {
    labelElePairArrWithoutAddressSection,
    addressSectionLabelElePairArr: addressSectionElePairArrList,
    setIsSameAddress,
    isSameAddress,
    setFields,
    fields,
    getValidationMessage,
    setApplicablePrograms,
  } as ReturnType<T>;
}
