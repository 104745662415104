import { SingleSelection } from '../../../../../components';
import { useTranslation } from '../../../../../hooks';
import { EnumDirection } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Access';

interface IProps {
    value: any;
    onChange: (e: any) => void;
}

const ChainNameSingleSelection = (props: IProps) => {
    const { value, onChange } = props;

    const { tc } = useTranslation();

    return (
        <SingleSelection
            label={tc('phSelection', { fieldName: tc('direction') })}
            value={value}
            onChange={onChange}
            clearSelect={() => { }}
            enumData={EnumDirection}
            nameFn={(item) => item}
        />
    );
};

export default ChainNameSingleSelection;
