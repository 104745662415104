import { useTranslation } from "../../../../hooks";
import useValidation from "../../../../hooks/useValidation";
import { ValidationConfigProps } from "../../../../hooks/useValidation/types";
import { requiredValidator } from "../../../../utils/validators/requiredValidator";
import { translatePrefix, TableColumnKey } from "../config";

interface IProps {
  label: string | undefined
}


const useDialogCreateLabelValidation = (props: IProps) => {
  const { label } = props;

  const { t, tc } = useTranslation(translatePrefix)

  const validationConfig: ValidationConfigProps = [
    {
      key: TableColumnKey.CustomerLabel,
      value: label,
      validators: [
        requiredValidator(
          tc('phInputField', { fieldName: t(TableColumnKey.CustomerLabel) })
        )
      ]
    }
  ]

  return useValidation(validationConfig)

}

export default useDialogCreateLabelValidation;