import { FilterTable } from "../../../components/Layout";
import ViewFilter from "./ViewFilter";
import {
  initFields,
  translatePrefix,
} from './config';
import api from '../../../api';
import { useAlerting, useTranslation } from '../../../hooks';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import useGetListParams from './helper/useGetListParams';
import { useZusDialogStore, useZusTranslatePrefixStore } from '../../../zustand/store';
import { GetListRes } from './types/GetListRes';
import DialogCreateLabel from "./DialogCreateLabel";
import useDialogCreateLabelValidation from "./helper/useDialogCreateLabelValidation";
import ViewTable from "./ViewTable";
import { ApiResult, ICustomerLabel } from "../../../api/types";
import { FilterFields } from "./types/FilterFields";
import useTableRows from "./helper/useTableRows";
import { downloadFiles, getFullApiResponse } from "../../../utils";
import { convertToExportRows } from "../../../features/common/filterTable/helpers/convertToExportRows";
import { useDispatch } from "react-redux";
import { setCustomerLabelList } from "../../../reducer/customerLabelSlice";

const CustomerLabel = () => {

  const { alerting } = useAlerting()
  const { t, tc } = useTranslation(translatePrefix);
  const { getListParams } = useGetListParams();
  const { setTranslatePrefix } = useZusTranslatePrefixStore();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [isDialogCreateLabelOpen, setIsDialogCreateLabelOpen] = useState(false);
  const [customerLabel, setCustomerLabel] = useState<string>('')
  const zusDialog = useZusDialogStore();
  const [list, setList] = useState<ApiResult<GetListRes>>({ rows: [], count: 0 });
  const [fields, setFields] = useState<FilterFields>(initFields);
  const [listParams, setListParams] = useState(getListParams(fields));


  const handleSettled = () => zusDialog.closeExtra();

  const handleSuccess = (data: ApiResult<GetListRes>) => {
    if (!data) return;
    setList(data);
  };

  const handleQuery = () => {
    zusDialog.openExtra('loadingDialog');
    const { ...cleanParams } = listParams;
    return api.CustomerManagement.customerLabel.getAll(cleanParams)
  };

  useEffect(() => setTranslatePrefix(translatePrefix), []);

  useQuery(['getCustomerLabel', listParams], handleQuery, {
    onSettled: handleSettled,
    onSuccess: handleSuccess,
  });

  const { totalCounts, convertToTableRows } = useTableRows({ apiList: list });

  const dialogCreateValidate = useDialogCreateLabelValidation({ label: customerLabel })

  const handleCreateLabelSubmit = async () => {
    const { allErrors } = dialogCreateValidate.validate();
    if (allErrors.length > 0) {
      alerting('warning', allErrors[0]);
      return;
    }

    const trimmedLabel = customerLabel.trim();

    const res = await api.CustomerManagement.customerLabel.createCustomerLabel({ customerLabel: trimmedLabel })
    if (!res) { return; }
    const newLabel = await api.MerchantPortal.customerLabel.getCustomerLabel();
    dispatch(setCustomerLabelList(newLabel as ICustomerLabel[]));
    setCustomerLabel('');
    setIsDialogCreateLabelOpen(false)
    setListParams({ ...listParams, currentTime: Date.now() });
    alerting('success', t('createdSuccessfully'))
  }

  const onFilterSearch = () => {
    setPage(page);
    setListParams({
      ...getListParams(fields),
      page: 0,
      currentTime: Date.now(),
    });
  }

  const onExport = async () => {
    const params = getListParams(fields);
    const { ...cleanParams } = params;

    if (totalCounts === 0) {
      return alerting('error', tc('no_data_export'));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.CustomerManagement.customerLabel.exportTable({ ...cleanParams, page, pageSize }, { signal });

    const rawRes = await getFullApiResponse(apiFn, totalCounts, true);
    if (rawRes.length === 0) return;

    const omitKeys = ['id', 'rawData'];
    const tableRows = convertToTableRows({ rows: rawRes } as ApiResult<GetListRes>);
    const exportRows = convertToExportRows({ data: tableRows, omitKeys, translateFunc: t });

    downloadFiles(`Customer Label`, exportRows, {});
  }

  const reFetchTable = () => {
    setListParams({
      ...getListParams(fields),
      currentTime: Date.now(),
    });
  }

  const onPageChange = (page: number) => {
    setPage(page);
    setListParams({ ...listParams, page });
  }

  return (
    <FilterTable
      filter={<ViewFilter
        openCreateLabelDialog={() => setIsDialogCreateLabelOpen(true)}
        fields={fields}
        setFields={setFields}
        onSearch={onFilterSearch}
      />}
      table={
        <>
          <DialogCreateLabel
            isOpen={isDialogCreateLabelOpen}
            customerLabel={customerLabel}
            setCustomerLabel={setCustomerLabel}
            handleCreateLabelSubmit={handleCreateLabelSubmit}
            closeDialog={() => setIsDialogCreateLabelOpen(false)}
          />
          <ViewTable
            page={page}
            list={list}
            onExport={onExport}
            onPageChange={onPageChange}
            reFetchTable={reFetchTable}
          />
        </>
      }
    />
  )
}

export default CustomerLabel