import { COLUMN_WIDTH } from '../../../../constants/ColumnWidth';
import { useGenGridCol } from '../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/TableColumnKeys';

const useTableColumns = () => {
  const columns = [
    useGenGridCol(TableColumnKey.source, {
      minWidth: COLUMN_WIDTH.PartnerName.MinWidth,
    }),
    useGenGridCol(TableColumnKey.partnerName, {
      minWidth: COLUMN_WIDTH.PartnerName.MinWidth,
    }),
    useGenGridCol(TableColumnKey.programName, {
      minWidth: COLUMN_WIDTH.ProgramName.MinWidth,
    }),
    useGenGridCol(TableColumnKey.programDisplayName, {
      minWidth: COLUMN_WIDTH.ProgramName.MinWidth,
    }),
    useGenGridCol(TableColumnKey.homeCurrency),
    useGenGridCol(TableColumnKey.maxCreditLimit),
    useGenGridCol(TableColumnKey.defaultCreditLimit),
    useGenGridCol(TableColumnKey.creditAdjustmentApprovalThreshold),
    useGenGridCol(TableColumnKey.defaultCustomerLabel),
    useGenGridCol(TableColumnKey.repaymentHouseAccount),
    useGenGridCol(TableColumnKey.rewardHouseAccount),
    useGenGridCol(TableColumnKey.creationTime, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.lastModifiedTime, {
      minWidth: COLUMN_WIDTH.LastModifiedDate.MinWidth,
    }),
  ];

  return columns;
};

export default useTableColumns;
