import { CustomerManagement } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/FeatureCode/MerchantPortal';

import api from '../../../api';
import { IdateFilter, Ipage } from '../../../api/types';
import { Iprefix } from '../../../hooks/useTranslation';
import { initZusParams } from '../../../utils/config';
import { createZusInstance } from '../../../zustand/store';

const translatePrefix: Iprefix = 'customerApplication';
const exportFileName = 'Customer Application';

const PermissionKey = CustomerManagement.CustomerApplication;

interface Ifields {
  customerLevel: string;
  customerNumber: string;
  assignedCustomerNumber: string;
  programName: string[];
  referralCode: string;
  applicationNumber: string;
  applicationType: string;
  applicationStatus: string;
  customerId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneCountryCode: string;
  phoneNumber: string;
  idvStatus: string;
  idvMethod: string;
  programAgentId: string;
  distributorAgentId: string;
  source: string;
  // kycReferenceCode: string;
}

const initFields: Ifields = {
  customerLevel: '',
  customerNumber: '',
  assignedCustomerNumber: '',
  programName: [],
  referralCode: '',
  applicationNumber: '',
  applicationType: '',
  applicationStatus: '',
  customerId: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  phoneCountryCode: '',
  phoneNumber: '',
  idvStatus: '',
  idvMethod: '',
  programAgentId: '',
  distributorAgentId: '',
  source: '',
  // kycReferenceCode: '',
};

interface IsearchParam extends IdateFilter {
  customerLevel: string;
  customerNumber: string;
  isCustomerAssigned: string;
  programNames: string[];
  referralCode: string;
  applicationNumber: string;
  type: string;
  status: string;
  customerId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneCountryCode: string;
  phoneNumber: string;
  kycIdvStatus: string;
  kycIdvMethod: string;
  programAgentId: string;
  distributorAgentId: string;
  // kycRef: string;
}

const tableTranslateKeyObj = {
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  operation: 'operation',
  applicationNumber: 'application_no',
  approvalProgress: 'approval_progress',
  versionNumber: 'version_no',
  latestVersion: 'latest_version',
  applicationType: 'application_type',
  programName: 'program_name',
  referralCode: 'referral_code',
  applicationReferralCode: 'applicationReferral_code',
  questionType: 'question_type',
  customerId: 'customer_id',
  customerName: 'customer_name',
  emailAddress: 'email_address',
  phoneNumber: 'phone_no',
  phoneCountryCode: 'phone_country_code',
  applicationStatus: 'application_status',
  kycReferenceCode: 'kyc_reference_code',
  idvStatus: 'idv_status',
  idvMethod: 'idv_method',
  idvManuallyVerifiedBy: 'idv_manually_verified_by',
  documentStatus: 'document_status',
  documentSubStatus: 'document_sub_status',
  documentVerifiedBy: 'document_verified_by',
  facialSimilarityStatus: 'facial_similarity_status',
  facialSimilaritySubStatus: 'facial_similarity_sub_status',
  facialSimilarityVerifiedBy: 'facial_similarity_verified_by',
  watchListStatus: 'watch_list_status',
  watchListSubStatus: 'watch_list_sub_status',
  watchListVerifiedBy: 'watch_list_verified_by',
  createdBy: 'created_by',
  customerNumber: 'customer_number',
  assignCustomerNumber: 'assign_customer_number',
  maxCreditLimit: 'max_credit_limit',
  creditLimit: 'credit_limit',
  assignedCustomerNumber: 'assigned_customer_number',
  programAgentId: 'program_agent_id',
  distributorAgentId: 'distributor_agent_id',
  source: 'source',
} as const;

const dialogTranslateKeyObj = {
  details: 'details',
  personalDetails: 'personal_details',
  title: 'title',
  firstName: 'first_name',
  lastName: 'last_name',
  chineseName: 'chinese_name',
  alias: 'alias',
  gender: 'gender',
  dateOfBirth: 'date_of_birth',
  nationality: 'nationality',
  identificationDocumentType: 'identification_document_type',
  identificationDocumentTypeOthers: 'identification_document_type_others',
  identificationNumber: 'identification_number',
  dateOfExpiry: 'date_of_expiry',
  dateOfIssue: 'date_of_issue',
  usCitizenship: 'us_citizenship',
  maritalStatus: 'marital_status',
  educationalStatus: 'educational_status',
  educationalStatusOthers: 'educational_status_others',
  purposeForApplying: 'purpose_for_applying',
  otherPurpose: 'other_purpose',
  isAuthorizedByYou: 'is_authorized_by_you',

  occupationDetails: 'occupation_details',
  employmentStatus: 'employment_status',
  companyName: 'company_name',
  jobTitle: 'job_title',
  jobTitleOthers: 'job_title_others',
  businessNatureOrIndustry: 'business_nature_or_industry',
  otherIndustry: 'other_industry',
  employmentStartDate: 'employment_start_date',
  officeTelephoneNumber: 'office_telephone_number',
  monthlySalaryInHkd: 'monthly_salary_in_hkd',
  otherMonthlyIncome: 'other_monthly_income',

  residentialDetails: 'residential_details',
  residentialStatus: 'residential_status',
  otherStatus: 'other_status',
  residentialTelephoneNumber: 'residential_telephone_number',
  residentAddressSection: 'resident_address_section',
  deliveryAddressSection: 'delivery_address_section',
  addressLine1: 'address_line_1',
  addressLine2: 'address_line_2',
  addressLine3: 'address_line_3',
  postalCode: 'postal_code',
  city: 'city',
  country: 'country',
  residentialAddressLine1: 'residential_address_line_1',
  residentialAddressLine2: 'residential_address_line_2',
  residentialAddressLine3: 'residential_address_line_3',
  residentialPostalCode: 'residential_postal_code',
  residentialCity: 'residential_city',
  residentialCountry: 'residential_country',
  deliveryAddressLine1: 'delivery_address_line_1',
  deliveryAddressLine2: 'delivery_address_line_2',
  deliveryAddressLine3: 'delivery_address_line_3',
  deliveryPostalCode: 'delivery_postal_code',
  deliveryCity: 'delivery_city',
  deliveryCountry: 'delivery_country',
  remarks: 'remarks',
  requestApproved: 'request_approved',
  requestRejected: 'request_rejected',
  idvDetails: 'idv_details',
  manualVerifyIdv: 'manual_verify_idv',
  verify: 'verify',
  success: 'success',
  fail: 'fail',
  retry: 'retry',
  noPermission: 'no_permission',
  applicantId: 'applicant_id',
  workflowRunId: 'workflow_run_id',
  switchToManualReview: 'switch_to_manual_review',
  retryIdv: 'retry_idv',
  successRetryIdv: 'success_retry_idv',
  successManualReviewSwitch: 'success_manual_review_switch',
  successAssignCustomerNumber: 'success_assign_customer_number',
  successManualVerifyIdv: 'success_manual_verify_idv',
  failManualVerifyIdv: 'fail_manual_verify_idv',
  customerLevel: 'customer_level',
  placeOfIssue: 'place_of_issue',
  sameAddressCheckbox: 'same_address_checkbox',
  editSuccessfulMessage: 'edit_successful_message',
  editApplicationRemarks: 'edit_application_remarks',
  questionType: 'question_type',
  source: 'source',
} as const;

const translateKeyObj = { ...tableTranslateKeyObj, ...dialogTranslateKeyObj } as const;

const omitKeyObj = Object.freeze({
  export: ['rawData'],
  table: [],
});

type ApiParam = Ipage & Partial<IsearchParam>;

const useZusParams = createZusInstance<ApiParam>(initZusParams);

const apiObj: {
  export: (params: ApiParam, config?: any) => Promise<any>;
  table: (params: ApiParam, config?: any) => Promise<any>;
  details: (params: { customerApplicationId: string }, config?: any) => Promise<any>;
  approve: (
    params: { id: string | number; remarks?: string; customerNumber?: string },
    config?: any
  ) => Promise<any>;
  reject: (
    params: { id: string | number; remarks?: string; customerNumber?: string },
    config?: any
  ) => Promise<any>;
  assignCustomerNumber: (
    params: {
      applicationNumber: string;
      customerNumber: string;
      customerLevel: string;
      programName: string;
      referralCode: string;
    },
    config?: any
  ) => Promise<any>;
  manualVerifyIdv: (
    params: { applicationNumber: string; remarks: string },
    config?: any
  ) => Promise<any>;
  retryIdv: (params: { applicationNumber: string; remarks: string }, config?: any) => Promise<any>;
  switchManualReview: (params: { applicationNumber: string }, config?: any) => Promise<any>;
  manualFailIdv: (
    params: { applicationNumber: string; remarks: string },
    config?: any
  ) => Promise<any>;
  decryptIdNumber: (params: { applicationNumber: string }, config?: any) => Promise<any>;
  resubmit: (
    params: Partial<ItableApiRes> & {
      answers: Partial<IdetailsApiRes>;
      rejectApplicationRemark: string;
    },
    config?: any
  ) => Promise<any>;
  applicableProgram: (
    params: { customerId: string; includePending: boolean },
    config?: any
  ) => Promise<IapplicableProgram[]>;
} = {
  export: api.CustomerManagement.customerApplication.getExport,
  table: api.CustomerManagement.customerApplication.getAll,
  details: api.CustomerManagement.customerApplication.getDetail,
  approve: api.CustomerManagement.customerApplication.postApprove,
  reject: api.CustomerManagement.customerApplication.postReject,
  assignCustomerNumber: api.CustomerManagement.customerApplication.postAssignCustomerNumber,
  manualVerifyIdv: api.CustomerManagement.customerApplication.postManualVerifyIdv,
  retryIdv: api.CustomerManagement.customerApplication.postRetryIdv,
  switchManualReview: api.CustomerManagement.customerApplication.postSwitchManualReview,
  manualFailIdv: api.CustomerManagement.customerApplication.postManualFailIdv,
  decryptIdNumber: api.CustomerManagement.customerApplication.getDecryptIdNumber,
  resubmit: api.CustomerManagement.customerApplication.postResubmit,
  applicableProgram: api.CustomerManagement.customerApplication.getApplicableProgram,
} as const;

interface ItableApiRes {
  id: number;
  merchantId: number;
  applicationNumber: string;
  customerId: string;
  version: number;
  isLatestVersion: boolean;
  type: number;
  status: number;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneCountryCode: string;
  phoneNumber: string;
  idIssuedBy: string;
  idType: number;
  dateOfBirth: string;
  programName: string;
  applicationReferralCode: any;
  questionType: string;
  maxCreditLimit: string;
  kycFacialStatus?: any;
  kycDocumentStatus?: any;
  kycWatchListStatus?: any;
  approvalsRequired: number;
  approvalsCount: number;
  kycIdvStatus: number;
  kycIdvMethod: number;
  idNumberEncrypted: string;
  cipher: string;
  lastModifiedDate: string;
  referralCode: string;
  customerNumber?: any;
  customerLevel?: any;
  idNumberHash: string;
  kycFacialSubStatus?: any;
  kycDocumentSubStatus?: any;
  kycWatchListSubStatus?: any;
  kycIdvManuallyVerifiedBy: string;
  kycRef: IkycRef;
  kycRemarks: string;
  creditLimit: string;
  customer_application_approvals: IcustomeApplicationApproval[];
  programAgentId: string;
  distributorAgentId: string;
  source: number;
}

interface IcustomeApplicationApproval {
  id: number;
  merchantId: number;
  requestRecordId: number;
  requestType: number;
  status: number;
  remark?: string;
  approvalPermission: string;
  approvedBy?: string;
  approvalDate?: string;
  attachment: string;
  seq: number;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
}

interface IdetailsApiFullRes {
  id: number;
  merchantId: number;
  customerApplicationId: number;
  questionVersion: number;
  answers: IdetailsApiRes;
}
export interface TcspApplicationAnswer {
  answers: Answers;
  questionVersion: number;
}

export interface Answers {
  remarks: string;
  signature: string;
  signatureDate: Date;
  sourceOfFunds: number;
  totalAssetHKD: string;
  sourceOfWealth: number;
  agreementOfTAndC: boolean;
  enablePrePayment: boolean;
  expectedTxPerMonth: string;
  obtainPersonalInfo: boolean;
  trustAccountReason: number;
  sourceOfFundsOthers: string;
  enableAdvancePayment: boolean;
  sourceOfWealthOthers: string;
  authorizedToThirdParty: boolean;
  seniorPublicFigureList: SeniorPublicFigureList[];
  expectedTxAmountPerMonth: string;
  trustAccountReasonOthers: string;
  authorizedToThirdPartyReason: string;
  seniorPublicFigureDeclaration: boolean;
}

export interface SeniorPublicFigureList {
  country: string;
  lastName: string;
  position: string;
  sequence: number;
  firstName: string;
  department: string;
  countryCode: string;
  relationship: string;
  nameLocalized: string;
}

interface IdetailsApiRes {
  alias: string;
  title: number;
  gender: string;
  industry: number;
  jobTitle: number;
  chineseName: string;
  companyName: string;
  nationality: string;
  deliveryCity: string;
  idTypeOthers: string;
  idDateOfIssue: string;
  maritalStatus: number;
  usCitizenship: boolean;
  educationLevel: number;
  idDateOfExpiry: string;
  industryOthers: string;
  jobTitleOthers: string;
  otherIncomeHKD: string;
  deliveryCountry: string;
  residentialCity: string;
  employmentStatus: number;
  monthlySalaryHKD: string;
  residentialStatus: number;
  deliveryPostalCode: string;
  purposeForApplying: number;
  residentialCountry: string;
  employmentStartDate: string;
  deliveryAddressLine1: string;
  deliveryAddressLine2: string;
  deliveryAddressLine3: string;
  educationLevelOthers: string;
  officeTelephoneNumber: string;
  residentialPostalCode: string;
  authorizedToThirdParty: boolean;
  residentialAddressLine1: string;
  residentialAddressLine2: string;
  residentialAddressLine3: string;
  residentialStatusOthers: string;
  purposeForApplyingOthers: string;
  sameAsResidentialAddress: boolean;
  residentialTelephoneNumber: string;
  tcspApplication?: TcspApplicationAnswer;
  idNumber?: string;
}

type IeditApiParams = IgeneralInfoEditApiParams & { answers: IanswersEditApiParams };

type IgeneralInfoEditApiParams = {
  firstName: string;
  lastName: string;
  programName: string;
  customerId: string;
  idIssuedBy: string;
  idType: number;
  idNumber: string;
  dateOfBirth: string;
  referralCode: string;
};

type IanswersEditApiParams = {
  title: number;

  chineseName: string;
  alias: string;
  gender: string;

  nationality: string;

  idTypeOthers: string;

  idDateOfIssue: string;
  idDateOfExpiry: string;
  usCitizenship: boolean;
  maritalStatus: number;
  educationLevel: number;
  educationLevelOthers: string;
  purposeForApplying: number;
  purposeForApplyingOthers: string;
  authorizedToThirdParty: boolean;
  employmentStatus: number;
  companyName: string;
  jobTitle: number;
  jobTitleOthers: string;
  industry: number;
  industryOthers: string;
  employmentStartDate: string;
  officeTelephoneNumber: string;
  monthlySalaryHKD: string;
  otherIncomeHKD: string;
  residentialStatus: number;
  residentialStatusOthers: string;
  residentialTelephoneNumber: string;

  residentialAddressLine1: string;
  residentialAddressLine2: string;
  residentialAddressLine3: string;
  residentialPostalCode: string;
  residentialCity: string;
  residentialCountry: string;
  deliveryAddressLine1: string;
  deliveryAddressLine2: string;
  deliveryAddressLine3: string;
  deliveryPostalCode: string;
  deliveryCity: string;
  deliveryCountry: string;
  sameAsResidentialAddress: boolean;
};

enum EnumOperationDialogMode {
  approval = 'approval',
  details = 'details',
  manualVerifyIdv = 'manualVerifyIdv',
}

interface IkycRef {
  applicantId: string;
  workflowRunId: string;
}

interface IapplicableProgram {
  id: number;
  merchantId: number;
  programName: string;
  programDisplayName: string;
  creditLimit: string;
  maxCreditLimit: string;
  homeCurrency: string;
  referralCodes: ReferralCode[];
  applicationReferralCode: ReferralCode[];
  questionType: string;
  source: number;
}

interface ReferralCode {
  propertyName: any;
  distributorAgentId: any;
  programAgentId: any;
  id: number;
  merchantId: number;
  programName: string;
  referralCode: string;
  status: number;
  kycIdvMethod: number;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  questionType: number;
  source: number;
}

export {
  initZusParams,
  translatePrefix,
  exportFileName,
  PermissionKey,
  type Ifields,
  initFields,
  type IsearchParam,
  useZusParams,
  translateKeyObj,
  omitKeyObj,
  apiObj,
  type ItableApiRes,
  type IkycRef,
  type IcustomeApplicationApproval,
  type IdetailsApiFullRes,
  type IdetailsApiRes,
  type IapplicableProgram,
  type IeditApiParams,
  type IgeneralInfoEditApiParams,
  type IanswersEditApiParams,
  EnumOperationDialogMode,
};
