export enum FilterFieldsKeys {
  createdDateFrom = 'createdDateFrom',
  createdDateTo = 'createdDateTo',
  lastModifiedTimeFrom = 'lastModifiedTimeFrom',
  lastModifiedTimeTo = 'lastModifiedTimeTo',

  customerId = 'customerId',
  customerNumber = 'customerNumber',
  purchaseOrderId = 'purchaseOrderId',
  eShopReferenceId = 'eShopReferenceId',
  purchaseStatus = 'purchaseStatus',
}

export interface FilterFields {
  [FilterFieldsKeys.createdDateFrom]: string;
  [FilterFieldsKeys.createdDateTo]: string;
  [FilterFieldsKeys.lastModifiedTimeFrom]: string;
  [FilterFieldsKeys.lastModifiedTimeTo]: string;

  [FilterFieldsKeys.customerId]: string;
  [FilterFieldsKeys.customerNumber]: string;
  [FilterFieldsKeys.purchaseOrderId]: string;
  [FilterFieldsKeys.eShopReferenceId]: string;
  [FilterFieldsKeys.purchaseStatus]: string;
}
