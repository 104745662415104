import { MerchantPortal } from "@wallet-manager/pfh-pmp-node-def-types";
import axios from "../axiosInstance";

const getWalletLedgerTransactions = async (params: any, config?: any) => {
    const { pageSize, page, ...rest } = params;

    const input = {
        params: {
            ...rest,
            limit: pageSize,
            offset: pageSize * page,
        },
        ...config,
    };

    return await axios.get(MerchantPortal.EptTransaction.WalletLedgerTransaction.getAll, input);
};

const getExport = async (params: any, config?: any) => {
    const { pageSize, page, ...rest } = params;

    const input = {
        params: {
            ...rest,
            limit: pageSize,
            offset: pageSize * page,
        },
        ...config,
    };

    return await axios.get(MerchantPortal.EptTransaction.WalletLedgerTransaction.export, input);
};

export default { getWalletLedgerTransactions, getExport }