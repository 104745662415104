import { EnumLedgerTransactionType } from "@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master";

export const getWalletLedgerTransactionTypeDisplayValue = (
    type: number,
    transalateFunc: (val: any) => string
) => {
    const value = EnumLedgerTransactionType[type];

    if (!value) return '';
    return transalateFunc(value)
};