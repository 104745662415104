import { COLUMN_WIDTH } from '../../../../constants/ColumnWidth';
import { useGenGridCol } from '../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/TableColumnKeys';

const useTableColumns = () => {
  const columns = [
    useGenGridCol(TableColumnKey.PurchaseOrderId),
    useGenGridCol(TableColumnKey.CustomerId),
    useGenGridCol(TableColumnKey.CustomerNumber),
    useGenGridCol(TableColumnKey.AssetCurrency),
    useGenGridCol(TableColumnKey.AssetUnitPrice),
    useGenGridCol(TableColumnKey.AssetQuantity),
    useGenGridCol(TableColumnKey.AssetTotalAmount),
    useGenGridCol(TableColumnKey.PurchaseStatus),
    useGenGridCol(TableColumnKey.PaymentUnitPrice),
    useGenGridCol(TableColumnKey.PaymentAmount),
    useGenGridCol(TableColumnKey.ActualPaymentAmount),
    useGenGridCol(TableColumnKey.EShopReferenceId),
    useGenGridCol(TableColumnKey.ErrorReason),
    useGenGridCol(TableColumnKey.CreationTime),
    useGenGridCol(TableColumnKey.LastModifiedTime),
  ];

  return columns;
};

export default useTableColumns;
