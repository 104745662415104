import { ChangeEvent, useState } from 'react';

import { EnumCustomerAccountStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { SingleSelection } from '../../../../components';
import { useDatePicker } from '../../../../components/DatePicker';
import { Container, SelectChangeEvent } from '../../../../components/MuiGenerals';
import { MpTextField } from '../../../../components/TextField';
import CollapsibleFilters from '../../../../features/common/filterTable/components/Filters/CollapsibleFilters';
import ToggleFilterButton from '../../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import useCollapsibleFilters from '../../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import FilterSectionActionRow from '../../../../features/common/filterTable/layout/FilterSectionActionRow';
import { Filter } from '../../../../features/common/filterTable/types';
import { useTranslation } from '../../../../hooks';
import {
  Ifields,
  initFields,
  initZusParams,
  IsearchParam,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';
import ProgramNameMultipleSelection from '../../../../features/common/filterTable/components/Filters/ProgramNameMultipleSelection';
import CustomerLabelAutoComplete from '../../../../features/common/filterTable/components/Filters/CustomerLabelAutoComplete';

export default function ViewFilter() {
  const zusParams = useZusParams();
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<Ifields>(initFields);

  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const getParams: () => IsearchParam = () => {
    const { customerAccountStatus: status, programName: programNames, ...rest } = fields;
    const { start: createdDateFrom, end: createdDateTo } = DateObj.CreationTime;
    const { start: lastModifiedDateFrom, end: lastModifiedDateTo } = DateObj.LastModifiedTime;

    return {
      ...initZusParams,
      ...rest,
      status,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom,
      lastModifiedDateTo,
      programNames
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const onChange =
    (field: keyof typeof fields) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
        setFields((fields) => ({ ...fields, [field]: e.target.value }));
      };

  const filters: Filter[] = [
    {
      labelKey: TK.creationTime,
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.lastModifiedTime,
      filter: <DateObj.LastModifiedTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.customerId,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.customerId) })}
          value={fields.customerId}
          onChange={onChange('customerId')}
        />
      ),
    },
    {
      labelKey: TK.customerNumber,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.customerNumber) })}
          value={fields.customerNumber}
          onChange={onChange('customerNumber')}
        />
      ),
    },
    {
      labelKey: TK.programName,
      filter: (
        <ProgramNameMultipleSelection
          value={fields.programName}
          onClear={() => {
            setFields((fields) => ({
              ...fields,
              programName: [],
            }))
          }}
          onChange={(value: string[]) => {
            setFields((fields) => ({
              ...fields,
              programName: value,
            }));
          }}
        />
      ),
    },
    {
      labelKey: TK.customerAccountStatus,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.customerAccountStatus) })}
          value={fields.customerAccountStatus}
          onChange={onChange('customerAccountStatus')}
          enumData={EnumCustomerAccountStatus}
          clearSelect={() => setFields((fields) => ({ ...fields, customerAccountStatus: '' }))}
        />
      ),
    },
    {
      labelKey: TK.programAgentId,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.programAgentId) })}
          value={fields.programAgentId}
          onChange={onChange('programAgentId')}
        />
      ),
    },
    {
      labelKey: TK.distributorAgentId,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.distributorAgentId) })}
          value={fields.distributorAgentId}
          onChange={onChange('distributorAgentId')}
        />
      ),
    },
    {
      labelKey: TK.customerLabel,
      filter: (
        <CustomerLabelAutoComplete
          value={fields.customerLabel}
          onInputChange={(value) => {
            setFields((fields) => ({ ...fields, customerLabel: value }))
          }}
        />
      ),
    },
  ];

  return (
    <Container disableGutters maxWidth={false}>
      <FilterSectionActionRow>
        <ToggleFilterButton isCollapse={isCollapse} onClick={toggleFilterCollapse} />
      </FilterSectionActionRow>

      <CollapsibleFilters
        filters={filters}
        isCollapse={isCollapse}
        onSearch={onSearch}
        onReset={onReset}
      />
    </Container>
  );
}
