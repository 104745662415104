import { EnumPurchaseOrderStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Eshop';
import { ApiResult } from '../../../../api/types';
import { useTranslation } from '../../../../hooks';
import { displayAmountCurrying, toDisplayTime } from '../../../../utils';

import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKeys';
import { TableRow } from '../types/TableRow';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;

  const { translate } = useTranslation();

  const formatPrice = displayAmountCurrying(0, 2);
  const createDisplayPrice = (price: string, currency: string) => {
    return `${formatPrice(price)} ${currency?.toUpperCase()}`;
  };

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row, index): TableRow => {
      const paymentUnitPrice = createDisplayPrice(row.paymentUnitPrice, row.paymentCurrency);
      const paymentAmount = createDisplayPrice(row.paymentAmount, row.paymentCurrency);
      const actualPaymentAmount = createDisplayPrice(row.actualPaymentAmount, row.paymentCurrency);

      const purchaseStatus = EnumPurchaseOrderStatus[row.status]
        ? translate(`enumConstants.${EnumPurchaseOrderStatus[row.status]}`)
        : '';

      return {
        id: index,
        [TableColumnKey.PurchaseOrderId]: String(row.id),
        [TableColumnKey.CustomerId]: row.customerId,
        [TableColumnKey.CustomerNumber]: row.customerNumber,
        [TableColumnKey.AssetCurrency]: row.purchaseAssetCurrency,
        [TableColumnKey.AssetUnitPrice]: formatPrice(row.purchaseAssetUnitAmount),
        [TableColumnKey.AssetQuantity]: String(row.quantity),
        [TableColumnKey.AssetTotalAmount]: formatPrice(row.purchaseAssetTotalAmount),
        [TableColumnKey.PurchaseStatus]: purchaseStatus,
        [TableColumnKey.PaymentUnitPrice]: paymentUnitPrice,
        [TableColumnKey.PaymentAmount]: paymentAmount,
        [TableColumnKey.ActualPaymentAmount]: actualPaymentAmount,
        [TableColumnKey.EShopReferenceId]: row.orderId,
        [TableColumnKey.ErrorReason]: row.errorReason,
        [TableColumnKey.CreationTime]: toDisplayTime(row.createdDate),
        [TableColumnKey.LastModifiedTime]: toDisplayTime(row.lastModifiedDate),
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
