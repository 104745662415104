import { EnumLedgerTransactionOrderType } from "@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master";

export const getWalletOrderTypeDisplayValue = (
    type: number,
    transalateFunc: (val: any) => string
) => {
    const value = EnumLedgerTransactionOrderType[type];

    if (!value) return '';
    return transalateFunc(value)
};