import { MpTextField } from '../../../../components';
import { useTranslation } from '../../../../hooks';

interface IProps {
  label?: string;
  value: string;
  onChange: (e: React.ChangeEvent<any>) => void;
}

const CustomerNumberTextField = (props: IProps) => {
  const { label, value, onChange } = props;
  const { t, tc } = useTranslation('purchaseAssetOrder');

  return (
    <MpTextField
      label={label || tc('phInputField', { fieldName: t('customerNumber') })}
      value={value}
      onChange={onChange}
    />
  );
};

export default CustomerNumberTextField;
