import { EptQuery } from "@wallet-manager/pfh-pmp-node-def-types/dist/src/Endpoint/MerchantPortal";
import axios from '../axiosInstance';

const getCustomerLabel = async () => {
  const res = await axios.get<never, any[]>(EptQuery.customerLabel.getCustomerLabels);
  return res
}

export default {
  getCustomerLabel
}