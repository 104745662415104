import { EnumCustomerAccountStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { Box } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';
import { useGetProgram } from '../../../../hooks/useProgram';
import { PALETTE } from '../../../../style/colorTheme';
import { IdetailApiRes, translateKeyObj as TK } from './config';

export default function useDetailDialogContent(details: IdetailApiRes | undefined) {
  const { t } = useTranslation('enumConstants');

  const { EnumProgramCurrency } = useGetProgram();

  const currency = details?.programName ? EnumProgramCurrency[details.programName] : '';

  const isCustomerLevelSame =
    details && details.fromState.customerLevel === details.toState.customerLevel;
  const isCustomerLabelSame =
    details && details.fromState.customerLabel === details.toState.customerLabel;
  const isStatusSame = details && details.fromState.status === details.toState.status;
  const isMaxCreditLimitSame =
    details && details.fromState.maxCreditLimit === details.toState.maxCreditLimit;

  const getToValueElement = ({
    displayValue,
    isShown,
  }: {
    displayValue: string;
    isShown: boolean;
  }) => {
    if (!isShown) {
      return <></>;
    }

    return (
      <>
        {' ➔ '}
        <span style={{ color: PALETTE.PrimaryText }}>{displayValue}</span>
      </>
    );
  };

  const labelElePairArr: [string, JSX.Element][] = [
    [TK.customerNumber, details ? <Box>{details.customerNumber}</Box> : <Box></Box>],
    [TK.programName, details ? <Box>{details.programName}</Box> : <Box></Box>],
    [
      TK.customerLabel,
      details ? (
        <Box>
          {details.fromState.customerLabel}
          {getToValueElement({
            displayValue: details.toState.customerLabel,
            isShown: !isCustomerLabelSame
          })}
        </Box>
      ) :
        <Box></Box>
    ],
    [
      TK.customerLevel,
      details ? (
        <Box>
          {details.fromState.customerLevel}
          {getToValueElement({
            displayValue: details.toState.customerLevel,
            isShown: !isCustomerLevelSame,
          })}
        </Box>
      ) : (
        <Box></Box>
      ),
    ],
    [
      TK.maxCreditLimit,
      details ? (
        <Box>
          {details.fromState.maxCreditLimit}
          {getToValueElement({
            displayValue: `${details.toState.maxCreditLimit} ${currency}`,
            isShown: !isMaxCreditLimitSame,
          })}
        </Box>
      ) : (
        <Box></Box>
      ),
    ],
    [
      TK.customerAccountStatus,
      details ? (
        <Box>
          {t(EnumCustomerAccountStatus[details.fromState.status])}
          {getToValueElement({
            displayValue: t(EnumCustomerAccountStatus[details.toState.status]),
            isShown: !isStatusSame,
          })}
        </Box>
      ) : (
        <Box></Box>
      ),
    ],
  ];

  return { labelElePairArr };
}
