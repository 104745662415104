import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  CobrandManagement,
  CustomerManagement,
  PriorityPass,
  Reward as RewardFeatureCode,
  TCSP,
  Transactions,
  EShop,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/FeatureCode/MerchantPortal';

import { useAppDispatch, useAppSelector } from '../reducer/hooks';
import { selectPermissions } from '../reducer/profileSlice';
import { setView } from '../reducer/stuffSlice';

export const FeatureCodes = {
  customerManagement: {
    customerList: CustomerManagement.CustomerList.prefix,
    customerAccount: CustomerManagement.CustomerAccount.prefix,
    customerApplication: CustomerManagement.CustomerApplication.prefix,
    customerLabel: CustomerManagement.CustomerLabel.prefix,
    cardBlockRequest: CustomerManagement.CardBlockRequest.prefix,
  },
  tcsp: {
    tcspAccount: TCSP.TcspAccount.prefix,
    tcspAccountApplication: TCSP.TcspAccountApplication.prefix,
    assetCustodyWallet: TCSP.AssetCustodyWallet.prefix,
  },
  transactions: {
    creditAdjustment: Transactions.CreditAdjustmentRequest.prefix,
    repaymentBatch: Transactions.RepaymentBatch.prefix,
    repaymentSchedule: Transactions.RepaymentSchedule.prefix,
    assetCustody: Transactions.AssetCustody.prefix,
    tcspTransactions: Transactions.TcspTransactions.prefix,
    walletLedgerTransactions: Transactions.WalletLedgerTransaction.prefix,
  },
  cobrandManagement: {
    referralCode: CobrandManagement.ReferralCode.prefix,
    cardFaceOverview: CobrandManagement.CardFaceOverview.prefix,
    exchangeRate: CobrandManagement.ExchangeRate.prefix,
    exchangeRateConfiguration: CobrandManagement.ExchangeRateConfiguration.prefix,
    program: CobrandManagement.Program.prefix,
    rewardRateConfiguration: CobrandManagement.RewardRateConfiguration.prefix,
    injectionFeeConfiguration: CobrandManagement.InjectionFeeConfiguration.prefix,
  },
  Reward: {
    Statement: RewardFeatureCode.Statement.prefix,
    RewardBatch: RewardFeatureCode.RewardBatch.prefix,
    RewardRecord: RewardFeatureCode.RewardRecord.prefix,
    QualifiedRepaymentTransaction: RewardFeatureCode.QualifiedRepaymentTransaction.prefix,
    QualifiedRepayment: RewardFeatureCode.QualifiedRepayment.prefix,
    RedeemHistory: RewardFeatureCode.RedeemHistory.prefix,
  },
  priorityPass: { membership: PriorityPass.Membership.prefix },
  eshop: {
    eshopOrder: EShop.EShopOrder.prefix,
    orderTransaction: EShop.OrderTransaction.prefix,
    purchaseAssetOrder: EShop.PurchaseAssetOrder.prefix,
  },
};

//###add-new-page
const { customerManagement, tcsp, transactions, cobrandManagement, Reward, priorityPass, eshop } =
  FeatureCodes;
export const routeList: { [key: string]: string[] } = [
  ['homepage', '', ''],
  ['customerList', 'customerList', customerManagement.customerList],
  ['customerAccount', 'customerAccount', customerManagement.customerAccount],
  ['customerApplication', 'customerApplication', customerManagement.customerApplication],
  ['customerLabel', 'customerLabel', customerManagement.customerLabel],
  ['tcspAccount', 'tcspAccount', tcsp.tcspAccount],
  ['tcspAccountApplication', 'tcspAccountApplication', tcsp.tcspAccountApplication],
  ['assetCustodyWallet', 'assetCustodyWallet', tcsp.assetCustodyWallet],
  ['creditAdjustmentRequest', 'creditAdjustmentRequest', transactions.creditAdjustment],
  ['repaymentBatch', 'repaymentBatch', transactions.repaymentBatch],
  ['repaymentSchedule', 'repaymentSchedule', transactions.repaymentSchedule],
  ['assetCustody', 'assetCustody', transactions.assetCustody],
  ['tcspTransaction', 'tcspTransaction', transactions.tcspTransactions],
  ['walletLedgerTransaction', 'walletLedgerTransaction', transactions.walletLedgerTransactions],
  ['referralCode', 'referralCode', cobrandManagement.referralCode],
  ['cardFaceOverview', 'cardFaceOverview', cobrandManagement.cardFaceOverview],
  ['exchangeRate', 'exchangeRate', cobrandManagement.exchangeRate],
  [
    'exchangeRateConfiguration',
    'exchangeRateConfiguration',
    cobrandManagement.exchangeRateConfiguration,
  ],
  ['program', 'program', cobrandManagement.program],
  ['cardBlockRequest', 'cardBlockRequest', customerManagement.cardBlockRequest],
  ['statement', 'statement', Reward.Statement],
  ['rewardBatch', 'rewardBatch', Reward.RewardBatch],
  ['rewardRecord', 'rewardRecord', Reward.RewardRecord],
  [
    'qualifiedRepaymentTransaction',
    'qualifiedRepaymentTransaction',
    Reward.QualifiedRepaymentTransaction,
  ],
  ['qualifiedRepayment', 'qualifiedRepayment', Reward.QualifiedRepayment],
  ['redeemHistory', 'redeemHistory', Reward.RedeemHistory],
  ['rebateWalletBalance', 'rebateWalletBalance', RewardFeatureCode.RebateWalletBalance.prefix],
  [
    'paRebateAdjustmentRequest',
    'paRebateAdjustmentRequest',
    RewardFeatureCode.PaRebateAdjustmentRequest.prefix,
  ],
  ['rewardRateConfiguration', 'rewardRateConfiguration', cobrandManagement.rewardRateConfiguration],
  ['membership', 'membership', priorityPass.membership],
  ['eshopOrder', 'eshopOrder', eshop.eshopOrder],
  ['orderTransaction', 'orderTransaction', eshop.orderTransaction],
  [
    'injectionFeeConfiguration',
    'injectionFeeConfiguration',
    cobrandManagement.injectionFeeConfiguration,
  ],
  ['eshopOrder', 'eshopOrder', eshop.eshopOrder],
  ['orderTransaction', 'orderTransaction', eshop.orderTransaction],
  ['purchaseAssetOrder', 'purchaseAssetOrder', eshop.purchaseAssetOrder],
].reduce((acc, [key, snakeCase, premission]) => {
  return { ...acc, [key]: [snakeCase, key, premission] };
}, {});

export default function useRoutePermission() {
  const pathname = useLocation().pathname.replace('/', '');
  const dispatch = useAppDispatch();
  const permissionList = useAppSelector(selectPermissions);
  const hasPermission = (key: string) => permissionList.includes(String(key).toLowerCase());
  const viewPermission = getViewPermission(pathname);

  useEffect(() => {
    dispatch(setView(viewPermission));
  }, [viewPermission]);

  const hasRoutePermission = (key: string) => {
    if (key === 'homepage' || key === 'accountSetting') return true;
    const [_, __, permission] = routeList[key] || [];
    return hasPermission(permission);
  };

  return { hasRoutePermission, viewPermission };
}

export function getViewPermission(pathname: string) {
  const [permission] = Object.values(routeList).find(([_, path]) => path === pathname) || [
    'homepage',
  ];
  return permission;
}
