import { useDispatch } from "react-redux";
import api from "../../../api";
import { DialogInOne } from "../../../components";
import { IdialogInOneProps } from "../../../components/DialogInOne";
import { useAlerting, useTranslation } from "../../../hooks";
import { translatePrefix } from "./config";
import { ICustomerLabel } from "../../../api/types";
import { setCustomerLabelList } from "../../../reducer/customerLabelSlice";

interface Iprops {
  deleteId: string | undefined
  closeDialog: () => void
  reFetchTable: () => void
}

const DialogDelete = (props: Iprops) => {
  const { deleteId, closeDialog, reFetchTable } = props;

  const { t } = useTranslation(translatePrefix)
  const { alerting } = useAlerting()
  const dispatch = useDispatch();

  const handleDeleteCustomerLabel = async () => {
    if (deleteId === undefined) { return }
    const res = await api.CustomerManagement.customerLabel.deleteCustomerLabel({ customerLabelId: deleteId })
    if (!res) { return; }
    const newLabel = await api.MerchantPortal.customerLabel.getCustomerLabel();
    dispatch(setCustomerLabelList(newLabel as ICustomerLabel[]));
    alerting('success', t('deletedSuccessfully'))
    reFetchTable()
    closeDialog()
  }

  const handleCloseDialog = () => {
    closeDialog()
  }
  const dialogContent = <></>

  const dialogConfig: IdialogInOneProps = {
    title: t('confirmToDelete'),
    self: {
      open: deleteId !== undefined,
      onClose: handleCloseDialog
    },
    content: dialogContent,
    onConfirm: handleDeleteCustomerLabel,
    onCancel: handleCloseDialog,
    size: 'sm',
    dialogActionStyling: { padding: '16px' },
  }

  return <DialogInOne {...dialogConfig} />
}

export default DialogDelete;