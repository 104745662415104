import { SingleSelection } from '../../../../../components';
import { SelectChangeEvent } from '../../../../../components/MuiGenerals';
import { useTranslation } from '../../../../../hooks';
import { useCustomerLabel } from '../../../../../hooks/useCustomerLabel';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
}

const CustomerLabelSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc } = useTranslation('common');

  const { EnumCustomerLabelList } = useCustomerLabel();

  let Enum = { ...EnumCustomerLabelList };

  if (value && !EnumCustomerLabelList[value]) {
    Enum = { ...EnumCustomerLabelList, [value]: value };
  }

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: tc('customerLabel') })}
      value={value || ''}
      clearSelect={onClear}
      onChange={onChange}
      enumData={Enum}
      nameFn={(item) => item}
    />
  );
};

export default CustomerLabelSingleSelection;
