import { useGenGridCol } from "../../../../utils/ComponentHelper";
import { TableColumnKey, translateKeyObj as TK } from "../config";
import { GridRenderCellParams } from "@mui/x-data-grid";

interface IProps {
  renderOperationsCell: (params: GridRenderCellParams) => React.ReactNode;
}

const useTableColumns = (props: IProps) => {
  const { renderOperationsCell } = props;
  const columns = [
    useGenGridCol(TableColumnKey.Operation, { renderCell: renderOperationsCell }),
    useGenGridCol(TableColumnKey.CustomerLabel),
    useGenGridCol(TableColumnKey.CreatedBy),
    useGenGridCol(TableColumnKey.CreationTime),
    useGenGridCol(TableColumnKey.LastModifiedTime),
  ];

  return columns;
}

export default useTableColumns;