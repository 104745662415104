import { ApiResult } from '../../../../api/types';
import { getWalletDirectionDisplayValue } from '../../../../helper/getWalletDirectionDisplayValue';
import { getWalletLedgerTransactionTypeDisplayValue } from '../../../../helper/getWalletLedgerTransactionTypeDisplayValue';
import { getWalletOrderTypeDisplayValue } from '../../../../helper/getWalletOrderTypeDisplayValue';
import { getWalletTypeDisplayValue } from '../../../../helper/getWalletTypeDisplayValue';
import { useTranslation } from '../../../../hooks';
import { displayAmountCurrying, toDisplayTime } from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKeys';
import { TableRow } from '../types/TableRow';

interface UseTableRowsProps {
    apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
    const { apiList } = props;

    const { te } = useTranslation();

      const convertToTableRows: ConvertToTableRowsType = (apiList) => {
        if (!apiList || !apiList?.rows) return [];
    
        const tableRows = apiList?.rows.map((row, index): TableRow => {
          const toCryptoDisplayAmount = displayAmountCurrying(row.decimals);
          const toCreditDisplayAmount = displayAmountCurrying(0, 2);
          
          const orderType = getWalletOrderTypeDisplayValue(row.orderType, te)
          const walletType = getWalletTypeDisplayValue(row.ledgerTransactionAssetCustodyWallet?.walletType, te)
          const transactionType = getWalletLedgerTransactionTypeDisplayValue(row.transType, te);
          const direction = getWalletDirectionDisplayValue(row.direction, te)

          const amount = toCryptoDisplayAmount(row.amount);
          const postBalance = toCreditDisplayAmount(row.postBalance);
          const postFrozenAmount = toCreditDisplayAmount(row.postFrozenBalance)
    
          const creationTime = toDisplayTime(row.createdDate);
          const lastModifiedTime = toDisplayTime(row.lastModifiedDate);
            
          return {
            id: index,
            [TableColumnKey.OrderId]: row.merchantOrderId,
            [TableColumnKey.OrderType]: orderType,
            [TableColumnKey.ProgramName]: row.ledgerTransactionAssetCustodyWallet?.programName,
            [TableColumnKey.CustomerNumber]: row.ledgerTransactionAssetCustodyWallet?.customerNumber,
            [TableColumnKey.ClientId]: row.clientId,
            [TableColumnKey.WalletType]: walletType, //TODO: toWalletDisplay
            [TableColumnKey.TransactionId]: row.ledgerTransactionId,
            [TableColumnKey.TransactionType]: transactionType, //TODO: toTransactionType
            [TableColumnKey.Direction]: direction, //TODO: toDirectionDisplay
            [TableColumnKey.Currency]: row.ledgerTransactionAssetCustodyWallet?.currency,
            [TableColumnKey.Amount]: amount,
            [TableColumnKey.PostBalance]: postBalance,
            [TableColumnKey.PostFrozenAmount]: postFrozenAmount,
            [TableColumnKey.FromClientId]: row.fromClientId,
            [TableColumnKey.ToClientId]: row.toClientId,
            [TableColumnKey.SendingAddress]: row.fromAddress,
            [TableColumnKey.ReceivingAddress]: row.toAddress,
            [TableColumnKey.CreationTime]: creationTime,
            [TableColumnKey.LastModifiedTime]: lastModifiedTime,
            rawData: row,
          };
        });
    
        return tableRows;
      };
    
      const rows = convertToTableRows(apiList);
    
      return { rows, totalCounts: apiList.count, convertToTableRows };
    };
    
    export default useTableRows;
    