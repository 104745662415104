import { CustomerManagement } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/FeatureCode/MerchantPortal';

import api from '../../../../api';
import { IdateFilter, Ipage } from '../../../../api/types';
import { Iprefix } from '../../../../hooks/useTranslation';
import { initZusParams } from '../../../../utils/config';
import { createZusInstance } from '../../../../zustand/store';

const translatePrefix: Iprefix = 'customerAccount';
const exportFileName = 'Customer Account';

const PermissionKey = CustomerManagement.CustomerAccount;

interface Ifields {
  customerId: string;
  customerNumber: string;
  programName: string[];
  customerAccountStatus: string;
  programAgentId: string;
  distributorAgentId: string;
  customerLabel: string;
}

const initFields: Ifields = {
  customerId: '',
  customerNumber: '',
  programName: [],
  customerAccountStatus: '',
  programAgentId: '',
  distributorAgentId: '',
  customerLabel: '',
};

interface IsearchParam extends IdateFilter {
  customerId: string;
  customerNumber: string;
  programNames: string[];
  status: string;
  programAgentId: string;
  distributorAgentId: string;
}

const translateKeyObj = {
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  customerId: 'customer_id',
  customerNumber: 'customer_number',
  customerLevel: 'customer_level',
  programName: 'program_name',
  customerAccountStatus: 'customer_account_status',
  maxCreditLimit: 'max_credit_limit',
  creditLimit: 'credit_limit',
  createdBy: 'created_by',
  operation: 'operation',
  edit: 'edit',
  remark: 'remark',
  maxCreditLimitAlert: 'max_credit_limit_alert',
  customerAccount: 'customer_account',
  requestRecord: 'request_record',
  programAgentId: 'program_agent_id',
  distributorAgentId: 'distributor_agent_id',
  customerLabel: 'customer_label'
} as const;

const omitKeyObj = Object.freeze({
  export: ['rawData'],
  table: [],
});

type ApiParam = Ipage & Partial<IsearchParam>;

const useZusParams = createZusInstance<ApiParam>(initZusParams);

const apiObj: {
  export: (params: ApiParam, config?: any) => Promise<any>;
  table: (params: ApiParam, config?: any) => Promise<any>;
  edit: (
    params: {
      customerNumber: string;
      maxCreditLimit: string;
      status: number;
      customerLevel: string;
      customerLabel: string
      remarks?: string;
    },
    config?: any
  ) => Promise<any>;
} = Object.freeze({
  export: api.CustomerManagement.customerAccount.getExport,
  table: api.CustomerManagement.customerAccount.getAll,
  edit: api.CustomerManagement.customerAccount.postEdit,
});

interface ItableApiRes {
  id: number;
  merchantId: number;
  programName: string;
  customerId: string;
  customerNumber: string;
  customerLevel: string;
  status: number;
  maxCreditLimit: string;
  creditLimit: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  programAgentId: string;
  distributorAgentId: string;
  customerLabel: string
}

export {
  initZusParams,
  translatePrefix,
  exportFileName,
  PermissionKey,
  type Ifields,
  initFields,
  type IsearchParam,
  useZusParams,
  translateKeyObj,
  omitKeyObj,
  apiObj,
  type ItableApiRes,
};
