import { EnumWalletType } from "@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master";

export const getWalletTypeDisplayValue = (
    type: number,
    translateFunc: (val: any) => string
) => {
    const value = EnumWalletType[type];

    if (!value) return '';

    return translateFunc(value);
}; 