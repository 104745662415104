import { ApiResult } from '../../../../api/types';
import { getSourceDisplayValue } from '../../../../helper/getSourceDisplayValue';
import { useTranslation } from '../../../../hooks';
import { displayAmountCurrying, toDisplayTime } from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKeys';
import { TableRow } from '../types/TableRow';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;
  const { te, translate } = useTranslation();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];
    const toCreditDisplayAmount = displayAmountCurrying(0, 2);

    const tableRows = apiList.rows.map((row, index): TableRow => {
      const maxCreditLimit = toCreditDisplayAmount(row.maxCreditLimit);
      const creditLimit = toCreditDisplayAmount(row.creditLimit);
      const creditAdjustmentApprovalThreshold = toCreditDisplayAmount(
        row.creditAdjustmentApprovalThreshold
      );
      const sourceDisplayValue = getSourceDisplayValue(row.source, translate);

      return {
        id: index,
        [TableColumnKey.source]: sourceDisplayValue,
        [TableColumnKey.partnerName]: row.partnerName,
        [TableColumnKey.programName]: row.programName,
        [TableColumnKey.programDisplayName]: row.programDisplayName,
        [TableColumnKey.homeCurrency]: row.homeCurrency,
        [TableColumnKey.maxCreditLimit]: maxCreditLimit,
        [TableColumnKey.defaultCreditLimit]: creditLimit,
        [TableColumnKey.creditAdjustmentApprovalThreshold]: creditAdjustmentApprovalThreshold,
        [TableColumnKey.defaultCustomerLabel]: row.defaultCustomerLabel,
        [TableColumnKey.repaymentHouseAccount]: row.wmRepaymentHouseAccount,
        [TableColumnKey.rewardHouseAccount]: row.wmRewardHouseAccount,
        [TableColumnKey.creationTime]: toDisplayTime(row.createdDate),
        [TableColumnKey.lastModifiedTime]: toDisplayTime(row.lastModifiedDate),
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
