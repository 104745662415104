import assetCustody from './assetCustody';
import creditAdjustmentRequest from './creditAdjustmentRequest';
import repaymentBatch from './repaymentBatch';
import repaymentSchedule from './repaymentSchedule';
import tcspTransactions from './tcspTransactions';
import walletLedgerTransactions from './walletLedgerTransactions';

export default {
  assetCustody,
  tcspTransactions,
  creditAdjustmentRequest,
  repaymentBatch,
  repaymentSchedule,
  walletLedgerTransactions
};
