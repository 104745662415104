export enum FilterFieldsKeys {
    createdDateFrom = 'createdDateFrom',
    createdDateTo = 'createdDateTo',
    lastModifiedTimeFrom = 'lastModifiedTimeFrom',
    lastModifiedTimeTo = 'lastModifiedTimeTo',
    programNames = 'programNames',
    walletType = 'walletType',
    customerNumber = 'customerNumber',
    clientId = 'clientId',
    orderId = 'orderId',
    orderType = 'orderType',
    transactionId = 'transactionId',
    transactionType = 'transactionType',
    direction = 'direction',
    currency = 'currency',
    sendingAddress = 'sendingAddress',
    receivingAddress = 'receivingAddress',
}

export interface FilterFields {
    [FilterFieldsKeys.createdDateFrom]: string;
    [FilterFieldsKeys.createdDateTo]: string;
    [FilterFieldsKeys.lastModifiedTimeFrom]: string;
    [FilterFieldsKeys.lastModifiedTimeTo]: string;
    [FilterFieldsKeys.programNames]: string[];
    [FilterFieldsKeys.walletType]: string;
    [FilterFieldsKeys.customerNumber]: string;
    [FilterFieldsKeys.clientId]: string;
    [FilterFieldsKeys.orderId]: string;
    [FilterFieldsKeys.orderType]: string[];
    [FilterFieldsKeys.transactionId]: string;
    [FilterFieldsKeys.transactionType]: string[];
    [FilterFieldsKeys.direction]: string;
    [FilterFieldsKeys.currency]: string;
    [FilterFieldsKeys.sendingAddress]: string;
    [FilterFieldsKeys.receivingAddress]: string;
}