import { EnumWalletType } from "@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master";
import { SingleSelection } from "../../../../../components";
import { useTranslation } from '../../../../../hooks';
import { SelectChangeEvent } from '../../../../../components/MuiGenerals';
interface IProps {
    label?: string;
    value: string;
    onChange: (e: SelectChangeEvent) => void;
}

const WalletTypeSingleSelection = (props: IProps) => {

    const { label, value, onChange } = props;

    const { tc, translate } = useTranslation();

    return (
        <SingleSelection
            label={label || tc('phSelection', { fieldName: tc('walletType') })}
            value={value}
            clearSelect={() => { }}
            onChange={onChange}
            enumData={EnumWalletType}
            nameFn={(name) => translate(`enumConstants.${name}`)}
        />
    )
}

export default WalletTypeSingleSelection