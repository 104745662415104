import { MpTextField } from "../../../../../components"
import { useTranslation } from "../../../../../hooks";

interface Iprops {
  label?: string
  value: string
  onChange: (e: React.ChangeEvent<any>) => void;
}

const CustomerLabelTextField = (props: Iprops) => {
  const { label, value, onChange } = props;
  const { tc } = useTranslation();
  return (
    <MpTextField
      label={label || tc('phInputField', { fieldName: tc('customerLabel') })}
      value={value}
      onChange={onChange}
      inputProps={{ maxLength: 30 }}
    />
  )
}

export default CustomerLabelTextField