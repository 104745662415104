import { CustomerManagement } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/FeatureCode/MerchantPortal';

import api from '../../../../api';
import { IdateFilter, Ipage } from '../../../../api/types';
import { Iprefix } from '../../../../hooks/useTranslation';
import { initZusParams } from '../../../../utils/config';
import { createZusInstance } from '../../../../zustand/store';

const translatePrefix: Iprefix = 'customerAccountRequestRecord';
const exportFileName = 'Customer Account Request Record';

const PermissionKey = CustomerManagement.CustomerAccount.RequestRecordTab;

interface Ifields {
  requestId: string;
  customerNumber: string;
  status: string;
}

const initFields: Ifields = {
  requestId: '',
  customerNumber: '',
  status: '',
};

interface IsearchParam extends IdateFilter {
  requestId: string;
  customerNumber: string;
  status: Number;
}

const translateKeyObj = {
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  requestId: 'request_id',
  approvalProgress: 'approval_progress',
  customerNumber: 'customer_number',
  status: 'status',
  programName: 'program_name',
  operation: 'operation',
  remark: 'remark',
  createdBy: 'created_by',
  customerAccount: 'customer_account',
  requestRecord: 'request_record',
  details: 'details',
  customerLevel: 'customer_level',
  maxCreditLimit: 'max_credit_limit',
  customerLabel: 'customer_label',
  customerAccountStatus: 'customer_account_status',
  approveOrReject: 'approve_or_reject',
} as const;

const omitKeyObj = Object.freeze({
  export: ['rawData'],
  table: [],
});

type ApiParam = Ipage & Partial<IsearchParam>;

const useZusParams = createZusInstance<ApiParam>(initZusParams);

const apiObj: {
  export: (params: ApiParam, config?: any) => Promise<any>;
  table: (params: ApiParam, config?: any) => Promise<any>;
  approvalDetails: (params: { id: string | number }, config?: any) => Promise<any>;
  details: (params: { id: string | number }, config?: any) => Promise<any>;
  approve: (params: { id: string | number }, config?: any) => Promise<any>;
  reject: (params: { id: string | number }, config?: any) => Promise<any>;
} = Object.freeze({
  export: api.CustomerManagement.editCustomerAccountRequest.getExport,
  table: api.CustomerManagement.editCustomerAccountRequest.getAll,
  approvalDetails: api.CustomerManagement.editCustomerAccountRequest.getApprovalDetail,
  details: api.CustomerManagement.editCustomerAccountRequest.getDetail,
  approve: api.CustomerManagement.editCustomerAccountRequest.postApprove,
  reject: api.CustomerManagement.editCustomerAccountRequest.postReject,
});

interface ItableApiRes {
  id: number;
  merchantId: number;
  customerId: string;
  status: number;
  approvalsRequired: number;
  approvalsCount: number;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  customerNumber: string;
  programName: string;
}

interface IdetailApiRes {
  id: number;
  merchantId: number;
  customerId: string;
  fromState: FromToState;
  toState: FromToState;
  status: number;
  approvalsRequired: number;
  approvalsCount: number;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  customerNumber: string;
  programName: string;
  customerLabel: string;
}

interface FromToState {
  status: number;
  customerLevel: string;
  maxCreditLimit: string;
  customerLabel: string;
}

export {
  initZusParams,
  translatePrefix,
  exportFileName,
  PermissionKey,
  type Ifields,
  initFields,
  type IsearchParam,
  useZusParams,
  translateKeyObj,
  omitKeyObj,
  apiObj,
  type ItableApiRes,
  type IdetailApiRes,
};
