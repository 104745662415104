import BigNumber from 'bignumber.js';
import { ChangeEvent, useEffect, useState } from 'react';

import { EnumCustomerAccountStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import {
  DialogInOne,
  MpTextField,
  SingleSelection
} from '../../../../components';
import { IdialogInOneProps } from '../../../../components/DialogInOne';
import { GridBox } from '../../../../components/Layout';
import { Box, SelectChangeEvent } from '../../../../components/MuiGenerals';
import MpTextFieldWithEndAdornment from '../../../../components/TextField/MpTextFieldWithEndAdornment';
import CustomerLevelSingleSelection from '../../../../features/common/filterTable/components/Filters/CustomerLevelSingleSelection';

import { useAlerting, useTranslation } from '../../../../hooks';
import useGridBoxInputValidation from '../../../../hooks/useGridBoxInputValidation';
import { useGetProgram } from '../../../../hooks/useProgram';
import { useZusDialogStore } from '../../../../zustand/store';
import {
  apiObj as api,
  ItableApiRes,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams
} from './config';
import CustomerLabelSingleSelection from '../../../../features/common/filterTable/components/Filters/CustomerLabelSingleSelection';

type Ifields = {
  customerLevel: string;
  maxCreditLimit: string;
  status: string;
  remarks: string;
  customerLabel: string
};
const initFields = {
  customerLevel: '',
  maxCreditLimit: '',
  status: '',
  remarks: '',
  customerLabel: ''
};

export default function DialogEdit() {
  const { t, tc } = useTranslation(translatePrefix);

  const zusDialog = useZusDialogStore();

  const zusParams = useZusParams();

  const { alerting } = useAlerting();

  const handleClose = () => zusDialog.close();

  const { validateGridBoxInput } = useGridBoxInputValidation();

  const { apiRes } = (zusDialog.meta || {}) as { apiRes: ItableApiRes | undefined };

  const [fields, setFields] = useState<Ifields>(initFields);

  const { EnumProgramCurrency, EnumProgramMaxCreditLimitList } = useGetProgram();

  const currency = apiRes?.programName ? EnumProgramCurrency[apiRes.programName] : '';

  const programMaxCreditLimit = apiRes?.programName
    ? EnumProgramMaxCreditLimitList[apiRes.programName]
    : '';

  const handleSubmit = async () => {
    if (!apiRes) {
      return alerting('error', 'No apiRes');
    }

    if (
      !Object.entries(fields).find(([key, val]) => {
        if (key === 'remarks') {
          return false;
        }

        return String(apiRes?.[key as keyof ItableApiRes]) !== String(val);
      })
    ) {
      return alerting('warning', tc('no_changes_made_warning'));
    }

    const validateMessage = validateGridBoxInput(labelElePairArr, [
      { fieldName: TK.remark, isSkipped: true },
    ]);

    if (validateMessage) {
      return alerting('warning', validateMessage);
    }

    if (
      BigNumber.isBigNumber(BigNumber(fields.maxCreditLimit)) &&
      BigNumber.isBigNumber(BigNumber(programMaxCreditLimit)) &&
      BigNumber(fields.maxCreditLimit).isGreaterThan(BigNumber(programMaxCreditLimit))
    ) {
      return alerting('warning', t(TK.maxCreditLimitAlert));
    }

    const { status, ...rest } = fields;

    const res = await api.edit({
      ...rest,
      status: Number(status),
      customerNumber: apiRes.customerNumber,
    });

    if (!res) {
      return;
    }

    alerting('success', tc('request_record_created'));
    await handleClose();
    zusParams.refetch();
  };

  useEffect(() => {
    setFields((prev) => ({
      ...prev,
      customerLevel: apiRes?.customerLevel || '',
      maxCreditLimit: apiRes?.maxCreditLimit || '',
      status: apiRes?.status ? String(apiRes.status) : '',
      customerLabel: apiRes?.customerLabel || ''
    }));
  }, [apiRes]);

  const changeHandler =
    (field: keyof Ifields) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
        setFields((prev) => ({ ...prev, [field]: e.target.value }));
      };

  const labelElePairArr: [string, JSX.Element][] = [
    [TK.customerNumber, <Box>{apiRes?.customerNumber}</Box>],
    [TK.programName, <Box>{apiRes?.programName}</Box>],
    [TK.customerLabel, <CustomerLabelSingleSelection
      label={tc('phSelection', { fieldName: t(TK.customerLabel) })}
      value={fields.customerLabel}
      onChange={changeHandler('customerLabel')}
      onClear={() => { }}
    />],
    [
      TK.customerLevel,
      <CustomerLevelSingleSelection
        showOptionsWithRewardRate
        value={fields.customerLevel}
        programName={apiRes?.programName || ''}
        onChange={changeHandler('customerLevel')}
        onClear={() => { }}
      />,
    ],
    [
      TK.maxCreditLimit,
      <MpTextFieldWithEndAdornment
        label={tc('phInputField', { fieldName: t(TK.maxCreditLimit) })}
        value={fields.maxCreditLimit}
        mode={'number'}
        endAdornmentNode={currency}
        decimalCount={2}
        onChange={changeHandler('maxCreditLimit')}
      />,
    ],
    [
      TK.customerAccountStatus,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.customerAccountStatus) })}
        value={fields.status}
        onChange={changeHandler('status')}
        enumData={EnumCustomerAccountStatus}
        clearSelect={() => { }}
      />,
    ],
    [
      TK.remark,
      <MpTextField
        label={tc('phInputField', { fieldName: t(TK.remark) })}
        value={fields.remarks}
        onChange={changeHandler('remarks')}
      />,
    ],
  ];

  const dialogConfig: IdialogInOneProps = {
    title: t(TK.edit),
    self: {
      open: zusDialog.match('operationDialog'),
      onClose: handleClose,
    },
    content: <GridBox labelElePairArr={labelElePairArr} />,
    onConfirm: handleSubmit,
    onCancel: handleClose,
  };

  if (!apiRes) <></>;

  return <DialogInOne {...dialogConfig} />;
}
