
import FilterSectionActionRow from '../../../features/common/filterTable/layout/FilterSectionActionRow'
import ToggleFilterButton from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton'
import useCollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters'
import { usePermission, useTranslation } from '../../../hooks'
import { CreateLabelPermissionKey, initFields, translatePrefix, FilterFieldsKeys } from './config'
import CollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters'
import { Filter } from '../../../features/common/filterTable/types'
import FilterBarCreateRecordButton from '../../../components/Button/FilterBarCreateRecordButton'
import { Dispatch, SetStateAction, useEffect } from 'react'
import FilterContainer from '../../../features/common/filterTable/layout/FilterContainer'
import { TableColumnKey } from './config'
import { useDatePicker } from '../../../components/DatePicker'
import { FilterFields } from './types/FilterFields'
import CustomerLabelTextField from '../../../features/common/filterTable/components/Filters/CustomerLabelTextField'
import CustomerLabelAutoComplete from '../../../features/common/filterTable/components/Filters/CustomerLabelAutoComplete'


interface ViewFilterProps {
  fields: FilterFields;
  setFields: Dispatch<SetStateAction<FilterFields>>;
  openCreateLabelDialog: () => void;
  onSearch: () => void;
}

const ViewFilter = (props: ViewFilterProps) => {
  const { fields, setFields, openCreateLabelDialog, onSearch } = props;
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();
  const { hasPermission } = usePermission();
  const { t } = useTranslation(translatePrefix)
  const isShowCreateLabelButton = hasPermission(CreateLabelPermissionKey)

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  }

  useEffect(() => {
    // sync fields state with date picker
    setFields((fields) => {
      return {
        ...fields,
        [FilterFieldsKeys.CreatedDateFrom]: DateObj.CreationTime.start,
        [FilterFieldsKeys.CreatedDateTo]: DateObj.CreationTime.end,
        [FilterFieldsKeys.LastModifiedTimeFrom]: DateObj.LastModifiedTime.start,
        [FilterFieldsKeys.LastModifiedTimeTo]: DateObj.LastModifiedTime.end,
      };
    });
  }, [
    DateObj.CreationTime.start,
    DateObj.CreationTime.end,
    DateObj.LastModifiedTime.start,
    DateObj.LastModifiedTime.end,
  ]);

  const filters: Filter[] = [
    {
      labelKey: TableColumnKey.CreationTime,
      filter: <DateObj.CreationTime.Picker type="dateTime" />
    },
    {
      labelKey: TableColumnKey.LastModifiedTime,
      filter: <DateObj.LastModifiedTime.Picker type="dateTime" />
    },
    {
      labelKey: TableColumnKey.CustomerLabel,
      filter: <CustomerLabelAutoComplete
        value={fields[FilterFieldsKeys.CustomerLabel]}
        onInputChange={(value) => setFields((fields) => ({
          ...fields,
          [FilterFieldsKeys.CustomerLabel]: value
        }))}
      />
    }
  ]
  return (
    <>
      <FilterSectionActionRow>
        <FilterBarCreateRecordButton
          title={t('addLabel')}
          isShow={isShowCreateLabelButton}
          onClick={openCreateLabelDialog}
        />
        <ToggleFilterButton isCollapse={isCollapse} onClick={toggleFilterCollapse} />
      </FilterSectionActionRow>
      <FilterContainer>
        <CollapsibleFilters
          isCollapse={isCollapse}
          filters={filters}
          onSearch={onSearch}
          onReset={onReset}
        />
      </FilterContainer>
    </>
  )
}

export default ViewFilter