import { MultipleSelection } from '../../../../../components';
import { useTranslation } from '../../../../../hooks';
import { EnumLedgerTransactionType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
  onClear: () => void;
}

function filterLedgerTransactionEnum(inputEnum: typeof EnumLedgerTransactionType) {
    // Values to exclude
    const excludeValues = [
        8, // freeze_balance
        9, // unfreeze_balance
    ];

    // Create a new object with filtered values
    const filteredEnum = {} as Record<string, number>;

    const enumEntries = Object.entries(inputEnum)
        .filter(([_, value]) => !excludeValues.includes(value as number)); // Exclude specified values


    enumEntries.forEach(([key, value]) => {
        filteredEnum[key] = value as number;
    });

    return filteredEnum;
}

const TransactionTypeMultipleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc, translate } = useTranslation();

  let selectValue = value || []

  if (!Array.isArray(selectValue)) {
    selectValue = [value]
  } 

  const FilteredLedgerTransactionOrderType = filterLedgerTransactionEnum(EnumLedgerTransactionType);

  return (
    <MultipleSelection
      label={label || tc('phSelection', { fieldName: tc('transactionType') })}
      value={selectValue}
      onChange={onChange}
      clearSelect={onClear}
      enumData={FilteredLedgerTransactionOrderType}
      nameFn={(name) => translate(`enumConstants.${name}`)}
    />
  );
};

export default TransactionTypeMultipleSelection;