import AutoComplete from '../../../../../components/AutoComplete'
import { useCustomerLabel } from '../../../../../hooks/useCustomerLabel';
import { sortAlphabetically } from '../../../../../utils';
import { useTranslation } from '../../../../../hooks';

interface IProps {
  value: string;
  onInputChange: (value: string) => void;
}

const CustomerLabelAutoComplete = (props: IProps) => {
  const { value, onInputChange } = props;

  const { tc } = useTranslation("common");

  const { EnumCustomerLabelList } = useCustomerLabel()

  const selectionArray = sortAlphabetically(Object.entries(EnumCustomerLabelList));
  const selectionOptions = selectionArray.map(([name, value]) => name);

  return (
    <AutoComplete
      value={value}
      triggerLength={1}
      onInputChange={(_, value) => onInputChange(value)}
      options={selectionOptions}
      label={tc('phInputField', { fieldName: tc('customerLabel') })}
      setOptions={() => { }}
      noOptionText={tc('no_relevant_record')}
    />
  )
}

export default CustomerLabelAutoComplete