export enum TableColumnKey {
  source = 'source',
  partnerName = 'partnerName',
  programName = 'programName',
  programDisplayName = 'programDisplayName',
  homeCurrency = 'homeCurrency',
  maxCreditLimit = 'maxCreditLimit',
  defaultCreditLimit = 'defaultCreditLimit',
  creditAdjustmentApprovalThreshold = 'creditAdjustmentApprovalThreshold',
  repaymentHouseAccount = 'repaymentHouseAccount',
  rewardHouseAccount = 'rewardHouseAccount',
  creationTime = 'creationTime',
  lastModifiedTime = 'lastModifiedTime',
  defaultCustomerLabel = 'defaultCustomerLabel'
}
