import React, { SetStateAction } from 'react';
import CurrencySingleSelection from '../../../common/filterTable/components/Filters/CurrencySingleSelection';
import CustomerNumberTextField from '../../../common/filterTable/components/Filters/CustomerNumberTextField';
import OrderIdTextField from '../../../common/filterTable/components/Filters/OrderIdTextField';
import ReceivingAddressTextField from '../../../common/filterTable/components/Filters/ReceivingAddressTextField';
import SendingAddressTextField from '../../../common/filterTable/components/Filters/SendingAddressTextField';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';
import { Filter } from '../../../common/filterTable/types';
import CollapsibleFilters from '../../../common/filterTable/components/Filters/CollapsibleFilters';
import ProgramNameMultipleSelection from '../../../common/filterTable/components/Filters/ProgramNameMultipleSelection';
import WalletTypeSingleSelection from '../../../common/filterTable/components/Filters/WalletTypeSingleSelection';
import OrderTypeMultipleSelection from '../../../common/filterTable/components/Filters/OrderTypeMultipleSelection';
import TransactionIdTextField from '../../../common/filterTable/components/Filters/TransactionIdTextField';
import TransactionTypeMultipleSelection from '../../../common/filterTable/components/Filters/TransactionTypeMultipleSelection';
import DirectionSingleSelection from '../../../common/filterTable/components/Filters/DirectionSingleSelection';
import ClientIdTextField from '../../../common/filterTable/components/Filters/ClientIdTextField';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  isCollapse?: boolean;
  DateObj: any;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, DateObj, isCollapse } = props;

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const filters: Filter[] = [
    { labelKey: 'creationTime', filter: <DateObj.CreationTime.Picker type="dateTime" /> },
    { labelKey: 'lastModifiedTime', filter: <DateObj.LastModifiedTime.Picker type="dateTime" /> },
    {
      labelKey: FilterFieldsKeys.programNames,
      filter: (
        <ProgramNameMultipleSelection
          value={fields[FilterFieldsKeys.programNames]}
          onClear={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.currency]: '' }));
          }}
          onChange={(value: string[]) => {
            updateField(FilterFieldsKeys.programNames, value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.walletType,
      filter: (
        <WalletTypeSingleSelection
          value={fields[FilterFieldsKeys.walletType]}
          onChange={(e) => {
            updateField(FilterFieldsKeys.walletType, e.target.value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.customerNumber,
      filter: (
        <CustomerNumberTextField
          value={fields[FilterFieldsKeys.customerNumber]}
          onChange={(e) => updateField(FilterFieldsKeys.customerNumber, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.clientId,
      filter: (
        <ClientIdTextField
          value={fields[FilterFieldsKeys.clientId]}
          onChange={(e) => updateField(FilterFieldsKeys.clientId, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.orderId,
      filter: (
        <OrderIdTextField
          value={fields[FilterFieldsKeys.orderId]}
          onChange={(e) => updateField(FilterFieldsKeys.orderId, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.orderType,
      filter: (
        <OrderTypeMultipleSelection
          value={fields[FilterFieldsKeys.orderType]}
          onClear={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.orderType]: [] }));
          }}
          onChange={(value: string[]) => {
            updateField(FilterFieldsKeys.orderType, value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.transactionId,
      filter: (
        <TransactionIdTextField
          value={fields[FilterFieldsKeys.transactionId]}
          onChange={(e) => updateField(FilterFieldsKeys.transactionId, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.transactionType,
      filter: (
        <TransactionTypeMultipleSelection
          value={fields[FilterFieldsKeys.transactionType]}
          onClear={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.transactionType]: [] }));
          }}
          onChange={(value: string[]) => {
            updateField(FilterFieldsKeys.transactionType, value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.direction,
      filter: (
        <DirectionSingleSelection
          value={fields[FilterFieldsKeys.direction]}
          onChange={(e) => {
            updateField(FilterFieldsKeys.direction, e.target.value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.currency,
      filter: (
        <CurrencySingleSelection
          programName={fields[FilterFieldsKeys.programNames]}
          value={fields[FilterFieldsKeys.currency]}
          onClick={(e) =>
            updateField(FilterFieldsKeys.currency, (e.target as HTMLElement).dataset.value)
          }
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.sendingAddress,
      filter: (
        <SendingAddressTextField
          value={fields[FilterFieldsKeys.sendingAddress]}
          onChange={(e) => updateField(FilterFieldsKeys.sendingAddress, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.receivingAddress,
      filter: (
        <ReceivingAddressTextField
          value={fields[FilterFieldsKeys.receivingAddress]}
          onChange={(e) => updateField(FilterFieldsKeys.receivingAddress, e.target.value)}
        />
      ),
    },
   

  ];

  return (
    <>
      <CollapsibleFilters
        isCollapse={isCollapse}
        filters={filters}
        onSearch={onSearch}
        onReset={onReset}
      />
    </>
  );
};

export default FilterSection;