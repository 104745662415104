export enum TableColumnKey {
    OrderId = 'orderId',
    OrderType = 'orderType',
    ProgramName = 'programName',
    CustomerNumber = 'customerNumber',
    ClientId = 'clientId',
    WalletType = 'walletType',
    TransactionId = 'ledgerTransactionId',
    TransactionType = 'transactionType',
    Direction = 'direction',
    Currency = 'currency',
    Amount = 'amount',
    PostBalance = 'postBalance',
    PostFrozenAmount = 'postFrozenAmount',
    FromClientId = 'fromClientId',
    ToClientId = 'toClientId',
    SendingAddress = 'fromAddress',
    ReceivingAddress = 'toAddress',
    CreationTime = 'creationTime',
    LastModifiedTime = 'lastModifiedTime',
}