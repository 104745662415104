import { ApiResult } from "../../../../api/types"
import { toDisplayTime } from "../../../../utils";
import { GetListRes } from "../types/GetListRes";
import { TableColumnKey } from "../config";
import { TableRow } from "../types/TableRow";

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {

    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row: GetListRes, index: any): TableRow => {
      return {
        id: index,
        [TableColumnKey.CustomerLabel]: row.customerLabel,
        [TableColumnKey.CreatedBy]: row.createdBy,
        [TableColumnKey.CreationTime]: toDisplayTime(row.createdDate),
        [TableColumnKey.LastModifiedTime]: toDisplayTime(row.lastModifiedDate),
        rawData: row,
      }
    });


    return tableRows;
  }



  const rows = convertToTableRows(apiList);

  return {
    rows,
    totalCounts: apiList.count,
    convertToTableRows
  };
}

export default useTableRows;