import { MpTextField } from '../../../../../components';
import { useTranslation } from '../../../../../hooks';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
}

const TransactionIdTextField = (props: IProps) => {
  const { label, value, onChange } = props;
  const { tc } = useTranslation();

  return (
    <MpTextField
      label={label || tc('phInputField', { fieldName: tc('transactionId') })}
      value={value}
      onChange={onChange}
    />
  );
};

export default TransactionIdTextField;
