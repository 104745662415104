import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { Box } from "../../../components/MuiGenerals";
import TableContainer from "../../../features/common/filterTable/layout/TableContainer"
import { usePermission, useTranslation } from "../../../hooks";
import useTabs from "../../../hooks/useTabs"
import { ExportPermissionKey, translatePrefix, translateKeyObj as TK, DeleteLabelPermissionKey } from "./config";
import useTableColumns from "./helper/useTableColumns";
import ButtonMenu from "../../../components/Button/ButtonMenu";
import useTableRows from "./helper/useTableRows";
import { ApiResult } from "../../../api/types";
import { GetListRes } from "./types/GetListRes";
import { ExportBtn } from "../../../components/Button";
import DialogDelete from "./DialogDelete";
import { useState } from "react";
import { dataGridDefaults } from '../../../utils/constant';
import { CustomPagination, NoRowsOverlay } from "../../../components/Layout";
import { dataGridColumnConfig } from "../../../utils/ComponentHelper";

interface ViewTableProps {
  page: number
  list: ApiResult<GetListRes>
  onExport: () => Promise<void>
  onPageChange: (page: number) => void
  reFetchTable: () => void
}

const ViewTable = (props: ViewTableProps) => {
  const { page, list, onExport, onPageChange, reFetchTable } = props;


  const { t, tc } = useTranslation(translatePrefix);
  const { Tabs } = useTabs([{ name: tc('table'), value: '' }]);
  const { rows, totalCounts } = useTableRows({ apiList: list });
  const [deleteId, setDeleteId] = useState<string | undefined>(undefined);

  const { hasPermission } = usePermission();
  const isShowExportBtn = hasPermission(ExportPermissionKey)

  const operationCell = (params: GridRenderCellParams<any, any, any>) => {
    return (
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
        <ButtonMenu
          title={t(TK.delete)}
          options={[
            {
              name: t(TK.delete),
              onClickFn: () => {
                setDeleteId(params.row.rawData.id)
              }
            }
          ]}
        />
      </Box>
    )
  }

  const hasOperationColPermission = hasPermission(DeleteLabelPermissionKey);

  const visibilityConfigArr = [
    {
      fieldName: TK.operation,
      hasPermission: hasOperationColPermission,
    },
  ];
  const columns = useTableColumns({ renderOperationsCell: operationCell });

  return (
    <TableContainer>
      <DialogDelete
        reFetchTable={reFetchTable}
        deleteId={deleteId}
        closeDialog={() => setDeleteId(undefined)}
      />
      <ExportBtn
        onExport={onExport}
        isShow={isShowExportBtn}
      />
      <Tabs>
        <DataGrid
          {...dataGridDefaults}
          initialState={dataGridColumnConfig(visibilityConfigArr)}
          rows={rows}
          rowCount={totalCounts}
          columns={columns}
          page={page}
          onPageChange={onPageChange}
          components={{
            NoRowsOverlay,
            Footer: CustomPagination,
          }}
          componentsProps={{
            footer: { totalRecords: totalCounts },
          }}
        />
      </Tabs>
    </TableContainer>
  )
}

export default ViewTable