
import axios from '../axiosInstance';
import { EptCustomerManagement } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/Endpoint/MerchantPortal';


const getAll = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(EptCustomerManagement.customerLabel.getAll, input);
}

const createCustomerLabel = async (param: any, config?: any) => {
  const input = {
    ...param,
    ...config
  }
  return await axios.post(EptCustomerManagement.customerLabel.create, input)
}

const deleteCustomerLabel = async (param: any, config?: any) => {
  const input = {
    ...param,
    ...config
  }
  return await axios.post(EptCustomerManagement.customerLabel.delete, input)
}

const exportTable = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(EptCustomerManagement.customerLabel.export, input)
}

export default {
  getAll,
  createCustomerLabel,
  deleteCustomerLabel,
  exportTable
}